<script lang="ts" setup>
import { useEditor, EditorContent, BubbleMenu } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import type { FormKitFrameworkContext } from "@formkit/core";

const props = defineProps<{
  context: FormKitFrameworkContext;
}>();

const editor = useEditor({
  content: "<p>I’m running Tiptap with Vue.js. 🎉</p>",
  extensions: [StarterKit],
  onUpdate: () => {
    props.context.node.input(editor?.value?.getHTML(), false);
  },
});

props.context.node.on("commit", ({ payload: value }) => {
  const isSame = editor.value?.getHTML() === value;
  if (isSame) {
    return;
  }
  editor.value?.commands.setContent(value, false);
});
</script>

<template>
  <div v-if="editor">
    <BubbleMenu :editor="editor" :tippy-options="{ duration: 50 }">
      <div class="bg-white p-2 flex gap-2">
        <button
          type="button"
          @click="editor.chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }"
        >
          Bold
        </button>
        <button
          type="button"
          @click="editor.chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }"
        >
          Italic
        </button>
        <button
          type="button"
          @click="editor.chain().focus().toggleStrike().run()"
          :class="{ 'is-active': editor.isActive('strike') }"
        >
          Strike
        </button>
      </div>
    </BubbleMenu>
    <EditorContent :editor="editor" />
  </div>
</template>

<style></style>
