<script setup lang="ts">
const router = useRouter();
const system = useSystemStore();
const { formModalOpen, dragging } = storeToRefs(system);
import { state } from "@/utils/drag-and-drop";

useHead({
  meta: [
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1, maximum-scale=1",
    },
  ],
});

onMounted(() => {
  state.on("dragStarted", () => {
    dragging.value = true;
  });

  state.on("dragEnded", () => {
    dragging.value = false;
  });

  router.beforeEach((_to, _from, next) => {
    formModalOpen.value = false;

    next();
  });
});
</script>

<template>
  <NuxtLayout>
    <NuxtLoadingIndicator
      color="#db2777"
      class="!bg-fk-accent !h-1 border-b border-b-fk-accent-lighter"
    />
    <NuxtPage />
    <CommandPaletteOverlay />
  </NuxtLayout>
</template>

<style>
:root {
  --fk-color-accent-base: #db2777;
  --fk-color-accent-lighter: #db2777;
}
</style>
