import { clone } from "@formkit/utils";
import type { JSONSchema7Object } from "json-schema";

export const options: JSONSchema7Object = {
  type: "array",
  items: {
    type: "object",
    properties: {
      value: { type: "string", description: "The value of the option" },
      label: { type: "string", description: "The label of the option" },
    },
    additionalProperties: false,
    required: ["value", "label"],
  },
  description:
    "The list of options the user can select from. Should be a comprehensive list of all possible values for the input.",
};

export const formattedOptions = addFormattedOptions(options);

export function addFormattedOptions(_options: JSONSchema7Object) {
  const options = clone<any>(_options);
  options.items.properties.formattedLabel = {
    anyOf: [
      {
        type: "string",
        description:
          "The new formatted label for the input. MUST be identical to the label, except it can contain basic html elements: p, strong, em, a",
      },
      {
        type: "null",
        description: "Preserve the existing formatted label.",
      },
    ],
  };
  options.items.required.push("formattedLabel");
  return options;
}
