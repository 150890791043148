import { log } from "./log";
import { useSystemStore } from "../stores/system";
import type { FormKitNode } from "@formkit/core";

export function handleBackendError(err: unknown, node?: FormKitNode) {
  if (
    node &&
    err &&
    typeof err === "object" &&
    "data" in err &&
    typeof err.data === "object" &&
    err.data &&
    "data" in err.data &&
    err.data.data &&
    typeof err.data.data === "object"
  ) {
    const body = err.data.data as Record<string, unknown>;
    if (body.name === "ZodError" && Array.isArray(body.issues)) {
      const [formErrors, inputErrors] = body.issues.reduce(
        ([formErrors, inputErrors], issue) => {
          if (issue.path) {
            const address = issue.path.join(".");
            if (address in inputErrors) {
              inputErrors[address].push(issue.message);
            } else {
              inputErrors[address] = [issue.message];
            }
          } else {
            formErrors.push(issue.message);
          }
          return [formErrors, inputErrors];
        },
        [[], {}] as [string[], Record<string, string[]>]
      );
      return node.setErrors(formErrors, inputErrors);
    }
  }

  if (err instanceof Error && "statusCode" in err) {
    if (
      "response" in err &&
      err.response &&
      typeof err.response === "object" &&
      "_data" in err.response &&
      typeof err.response._data === "object" &&
      err.response._data &&
      "message" in err.response._data &&
      typeof err.response._data.message === "string"
    ) {
      useSystemStore().displayError(err.response._data.message);
      return;
    } else if (err.statusCode === 402) {
      useSystemStore().showSubscribeModal = true;
      return;
    } else if (err.statusCode === 403) {
      useSystemStore().displayError(err.message);
      return;
    } else if (err.statusCode === 429) {
      useSystemStore().showRateLimitedModal = true;
      return;
    }
  }

  if (node) {
    node.setErrors([
      "Sorry, there was an error attempting to submit the form. Try again soon or contact support@formkit.com.",
    ]);
  } else {
    useSystemStore().displayError(
      "Sorry, there was an error attempting to submit the form, contact support@formkit.com."
    );
  }
  log(`Form failed: ${err}`);
  return;
}
