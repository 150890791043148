import type {
  ChatMessage,
  FormKitSchemaNode,
  FormNode,
  FormStore,
} from "~/types";
import { cloneAny, token } from "@formkit/utils";
import type { LLMChatMessageWithError } from "~/types";
import { inputsRequiringDetails } from "~/prompts/inputs";

interface ReadToolStreamParameters {
  history?: Ref<ChatMessage[]>;
  store?: FormStore;
  input?: FormNode;
  form?: FormNode[];
  [key: string]: any;
}

/** **
 * Read the tool stream on the front end. Perform known operations on the tool call, and any unknown ops should call the onToolCall callback.
 * @param stream - A ReadableStream<Uint8Array> created via MultiplexedStream class.
 * @param input - The input node to use for the tool call
 * @param onToolCall - The callback to use on each tool call
 */
export async function readToolStream(
  stream: ReadableStream<Uint8Array>,
  parameters?: ReadToolStreamParameters,
  onToolCall?: (toolName: string, stream: ReadableStream) => void
) {
  const history = parameters?.history ?? false;
  await readMultiplexedStream(
    stream.getReader(),
    (toolStreamName: string, stream: ReadableStream) => {
      const { type: toolName } = JSON.parse(toolStreamName) as { type: string };
      switch (toolName) {
        case "form_id":
          streamFormId(stream, parameters);
          return;
        case "modify_input_props":
          streamInputEdits(stream, parameters);
          return;
        case "modify_idx":
          streamInputPositions(stream);
          return;
        case "modify_bulk":
          streamBulkEdits(stream);
          return;
        case "create_inputs":
          streamOutline(stream, parameters);
          return;
        case "remove_inputs":
          streamRemoveInputs(stream);
          return;
        case "boolean_logic":
          // TODO: Implement boolean logic
          alert("boolean logic not implemented");
          return;
        case "create_outline":
          streamOutline(stream, parameters);
          return;
        case "create_input":
          streamDetailedInput(stream, parameters?.inputType, parameters);
          return;
        default:
          if (toolName.startsWith("detailed_input")) {
            streamDetailedInput(stream, toolName.substring(15), parameters);
            return;
          }
          if (toolName.endsWith("_message") && history) {
            const message = streamToChatMessage(
              stream,
              toolName === "error_message"
            );
            history.value.push(message);
          } else if (toolName === "suggestions" && history) {
            const message = streamToChatSuggestions(stream);
            history.value.push(message);
          }
          return onToolCall ? onToolCall(toolName, stream) : null;
      }
    }
  );
}

/**
 * Reads a stream to remove inputs by their names.
 * @param stream - A ReadableStream<Uint8Array>
 */
export async function streamRemoveInputs(stream: ReadableStream<Uint8Array>) {
  const form = useCurrentForm();
  const removedNames: string[] = [];
  for await (const chunk of jsonReader(stream.getReader(), {
    silent: ["names.*"],
  })) {
    for (const name of chunk.names) {
      if (!removedNames.includes(name)) {
        removedNames.push(name);
        removeInputsByName(form.value?.schema ?? [], name);
      }
    }
  }
}

/**
 * Reads a stream to apply bulk edits to inputs.
 * @param stream - A ReadableStream<Uint8Array>
 */
export async function streamBulkEdits(stream: ReadableStream<Uint8Array>) {
  const form = useCurrentForm();
  const appliedMap: Record<string, string> = {};

  for await (const chunk of jsonReader(stream.getReader(), {
    silent: ["changes.*.name"],
  })) {
    if (!form.value?.schema || !chunk.changes) continue;

    for (const change of chunk.changes) {
      change.__loading = true;
      const serialized = JSON.stringify(removeEmptyValues(change));
      if (appliedMap[change.name] !== serialized) {
        modifyInputByName(form.value.schema, change.name, change);
        appliedMap[change.name] = serialized;
      }
    }
  }
  if (!form.value?.schema) return;
  // Re-key all modified inputs to reboot them
  for (const change of Object.values(appliedMap)) {
    const parsed = JSON.parse(change);
    modifyInputByName(form.value.schema, parsed.name, {
      key: token(),
      __loading: false,
    });
  }
  structureSchema(form.value.schema);
  applyCurrentPositions(form.value.schema);
}

/**
 * Reads a stream to apply edits to inputs.
 * @param stream - A ReadableStream<Uint8Array>
 * @param parameters - The parameters to use for the edits
 */
export async function streamInputEdits(
  stream: ReadableStream<Uint8Array>,
  parameters?: ReadToolStreamParameters
) {
  if (parameters) {
    const store = useCurrentForm();
    let input: FormNode | undefined;
    for await (const chunk of jsonReader(stream.getReader(), {
      required: ["name", "type"],
      silent: ["options.*", "format"],
    })) {
      if (!input) {
        input = cloneAny(getInputByName(store.value?.schema ?? [], chunk.name));
      }
      if (store.value?.schema) {
        if (chunk.type && input && chunk.type !== input?.type) {
          let oldInput = input;
          // If we are changing the type we need to remove most of the old properties.
          input = { type: chunk.type, idx: input.idx, name: input.name };
          if ("if" in oldInput) {
            input.if = oldInput.if;
          }
        } else if (input) {
          input = {
            __loading: true,
            ...input,
            ...chunk,
          } as FormNode;
          modifyInputByName(store.value.schema, input.name, input);
        }
      }
    }
    if (input && store.value?.schema) {
      delete input?.__loading;
      modifyInputByName(store.value.schema, input.name, input);
    }
    if (store.value?.schema && input) {
      // Re-key the input to reboot it.
      replaceSchemaByIdx(store.value.schema, { ...input, key: token() });
    }
  }
}

/**
 * Reads a stream to a reactive ChatMessage object — specifically for suggestions replies.
 * @param stream - A ReadableStream<Uint8Array>
 * @returns A reactive ChatMessage object
 */
export function streamToChatSuggestions(
  stream: ReadableStream<Uint8Array>
): ChatMessage {
  const message = reactive<ChatMessage>({
    role: "suggestions",
    id: token(),
    content: [],
  });
  async function readTheSuggestions() {
    for await (const chunk of jsonReader(stream.getReader())) {
      if (chunk.suggestions) {
        message.content = chunk.suggestions;
      }
    }
  }
  readTheSuggestions();
  return message;
}

/**
 * Reads a stream to a reactive ChatMessage object.
 * @param stream - A ReadableStream<Uint8Array>
 * @returns A reactive ChatMessage object
 */
export function streamToChatMessage(
  stream: ReadableStream<Uint8Array>,
  isErrorMessage?: boolean
): ChatMessage {
  const message = reactive<ChatMessage>({
    role: "assistant",
    id: token(),
    content: "",
  });
  if (isErrorMessage) {
    (message as LLMChatMessageWithError).error = true;
  }
  async function readTheMessage() {
    const decoder = new TextDecoder();
    for await (const chunk of read(stream.getReader())) {
      message.content += decoder.decode(chunk);
    }
  }
  readTheMessage();
  return message;
}

/**
 * Applies the input positions from a modify_idx function call.
 * @param stream - The stream from a modify_idx function call
 * @returns
 */
export async function streamInputPositions(stream: ReadableStream<Uint8Array>) {
  const store = useCurrentForm();
  const result = await streamToString(stream);
  const { moves } = JSON.parse(result) as {
    moves: { new_idx: string; old_idx: string }[];
  };
  if (!Array.isArray(store.value?.schema)) return;
  applyMoves(store.value.schema, moves);
}

/**
 * Reads a stream to get the form id.
 * @param stream - A ReadableStream<Uint8Array>
 * @param parameters - The parameters to use for the form id
 */
async function streamFormId(
  stream: ReadableStream<Uint8Array>,
  parameters: Record<string, any> = {}
) {
  const { onId } = parameters;
  const id = await streamToString(stream);
  if (onId) onId(id);
}

/**
 * Reads a stream to create the outline of the form schema.
 * @param stream - A ReadableStream<Uint8Array>
 */
async function streamOutline(
  stream: ReadableStream<Uint8Array>,
  parameters: Record<string, any> = {}
) {
  let { store } = parameters as { store?: FormStore };
  if (!store) {
    store = useCurrentForm().value!;
  }
  if (!store.schema) return;

  for await (const [data, path] of jsonPathReader(stream.getReader(), [
    "title",
    "backgroundImageSearchTerm",
    "items.*",
  ] as const)) {
    if (path !== "items.*") {
      if (path === "backgroundImageSearchTerm") {
        store.theme.bgImageSearchQueryDefault = data;
      } else {
        store[path] = data;
      }
      continue;
    }

    const input = data as FormNode;

    if (input.idx in store.earlyInputBuffer) {
      store.schema.push(
        Object.assign(
          { key: token(), name: token() },
          correctSchema(store.earlyInputBuffer[input.idx])
        )
      );
      delete store.earlyInputBuffer[input.idx]; // eslint-disable-line
      structureSchema(store.schema);
    } else {
      const willLoadMore = inputsRequiringDetails.includes(input.type);
      const schemaNode = Object.assign(
        {
          key: token(),
          name: token(),
        },
        correctSchema(input as Record<string, any>),
        {
          outerClass: ` !max-w-none ${
            input.cols === "1" ? "col-span-1" : "col-span-2"
          } ${willLoadMore ? LOADING_CLASS_LIST : ""}`,
        },
        willLoadMore ? { __loading: true } : {}
      );
      schemaNode.delta = createLeastSignificantDelta(input.idx, -0.01);
      store.schema.push(schemaNode);
      sortSchema(store.schema);
      applyCurrentPositions(store.schema);
      structureSchema(store.schema);
    }
  }
}

/**
 * Reads a stream to create the detailed input schema.
 * @param stream - A ReadableStream<Uint8Array>
 */
async function streamDetailedInput(
  stream: ReadableStream<Uint8Array>,
  type: string,
  parameters?: { store?: FormStore; node?: FormKitSchemaNode }
) {
  let { store } = parameters ?? {};
  if (!store) {
    store = useCurrentForm().value!;
  }
  if (!store.schema) return;
  let inputDetails: FormKitSchemaNode | undefined;
  for await (const input of jsonReader(stream.getReader(), {
    required: [...requiredProps(type), "idx"],
    silent: silentProps(type),
  })) {
    inputDetails = input;
  }
  if (!inputDetails) return;
  if (parameters?.node) {
    Object.assign(parameters.node, inputDetails);
    if ("inputType" in parameters.node) {
      parameters.node.type = parameters.node.inputType;
      delete parameters.node.inputType;
    }
  }
  const itemInStoreSchema = getByIdx(store.schema, inputDetails.idx);
  if (!itemInStoreSchema) {
    store.earlyInputBuffer[inputDetails.idx] = inputDetails;
  } else {
    Object.assign(itemInStoreSchema, inputDetails);
    itemInStoreSchema.outerClass = (itemInStoreSchema.outerClass ?? "").replace(
      LOADING_CLASS_LIST,
      " [&>*]:transition-opacity "
    );
    delete itemInStoreSchema.__loading;

    // "reboot" the input.
    itemInStoreSchema.key = token();

    // Do some fancy thing andrew knows about
    setTimeout(() => {
      itemInStoreSchema.outerClass = itemInStoreSchema.outerClass.replace(
        "[&>*]:transition-opacity",
        ""
      );
    }, 250);
  }
}
