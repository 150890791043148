import { createInput, defineFormKitConfig } from "@formkit/vue";
import { rootClasses } from "./formkit.theme";
import { createProPlugin, inputs } from "@formkit/pro";
import {
  createAutoAnimatePlugin,
  createAutoHeightTextareaPlugin,
} from "@formkit/addons";
import { genesisIcons } from "@formkit/icons";
import { icons } from "@/assets/icons";
import type { FormKitNode, FormKitTypeDefinition } from "@formkit/core";
import { empty } from "@formkit/utils";
import WYSIWYG from "./components/inputs/WYSIWYG.vue";
import {
  checkbox,
  createSection,
  inner,
  legend,
  group as ogGroup,
  type FormKitInputs,
} from "@formkit/inputs";

import Heading from "./components/inputs/Heading.vue";
import Paragraph from "./components/inputs/Paragraph.vue";

const pro = createProPlugin("fk-85677db147f", inputs);

function required_if(node: FormKitNode, otherInput: string) {
  return !empty(node.at(otherInput)?.value) ? !empty(node.value) : true;
}
required_if.skipEmpty = false;

declare module "@formkit/inputs" {
  interface FormKitInputProps<Props extends FormKitInputs<Props>> {
    wysiwyg: {
      type: "wysiwyg";
      value?: string;
    };
    ogGroup: {
      type: "ogGroup";
      value: Record<string, any>;
    };
  }
}

const nonInputSchema = (outerClasses?: string) => {
  return {
    label: null,
    help: null,
    outer: {
      attrs: {
        class: `$classes.outer + ' col-span-2 ${outerClasses}'`,
      },
    },
  };
};

const wysiwyg = createInput(WYSIWYG);

const heading = createInput(
  Heading,
  { props: ["content"] },
  nonInputSchema("[&+[data-type=paragraph]]:-mt-[calc(var(--fk-input-gap)*.5)]")
);
const paragraph = createInput(
  Paragraph,
  { props: ["content"] },
  nonInputSchema()
);

const fieldset = createSection("outer", "fieldset");

//const group: FormKitTypeDefinition = {
//  type: "group",
//  schema: fieldset(legend(), inner("$slots.default")),
//};

// const section = createInput(Section, )

export default defineFormKitConfig({
  config: {
    rootClasses,
  },
  rules: {
    required_if,
  },
  messages: {
    en: {
      validation: {
        required_if: "This field is required",
      },
    },
  },
  inputs: {
    wysiwyg,
    heading,
    paragraph,
    checkboxsingle: checkbox,
    checkboxmultiple: checkbox,
    //group,
    ogGroup,
  },
  plugins: [pro, createAutoAnimatePlugin(), createAutoHeightTextareaPlugin()],
  icons: {
    ...genesisIcons,
    ...icons,
  },
});
