import { defineNuxtPlugin } from '#app/nuxt'
import { LazyEditIcon, LazyLoader, LazySiteHeaderContainer, LazyElementEditorWrapper, LazyPlaceholder, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["EditIcon", LazyEditIcon],
["Loader", LazyLoader],
["SiteHeaderContainer", LazySiteHeaderContainer],
["ElementEditorWrapper", LazyElementEditorWrapper],
["Placeholder", LazyPlaceholder],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
