import { defineStore } from "pinia";
import { getCurrentInstance } from "vue";

type Message = { message: string; timeout: any; flash: boolean };

export const useSystemStore = defineStore("systemStore", () => {
  const formModalOpen = ref(false);
  const showSubscribeModal = ref(false);
  const showRateLimitedModal = ref(false);
  const errorsFromBackend = useCookie<string[] | null>("errors");
  const initialErrors = (errorsFromBackend.value ?? []).map((message) => ({
    message,
    timeout: null,
    flash: false,
  }));
  errorsFromBackend.value = null;
  const errors = ref<Array<Message>>(initialErrors);
  const successes = ref<Array<Message>>([]);
  const viewportWidth = ref(1200);
  const showPromptHistory = ref(false);
  const editorIsNavigating = ref(false);
  const navigationFallbackTimer = ref<any>(null);
  const editorActivePageId = ref("form-page-1");
  const editorHistoryOpen = ref(false);
  const editorPreviewOpen = ref(false);
  const editorCanvasOpen = ref(true);
  const dragging = ref(false);
  const editorActiveSidebarTab = ref<string | null>("flow");
  const previewRenderQueue = ref<{ historyId: string; pageIndex: number }[]>(
    []
  );
  const activeElementControls = ref<HTMLElement | null>(null);

  const commandPaletteOpen = ref(false);
  type CommandId = (typeof commandPaletteCommands.value)[number]["id"];
  const commandPaletteMode = ref<CommandId | null>(null);
  const commandPaletteCommands = ref([
    {
      name: "Edit Form with AI",
      id: "chat",
      type: "app",
      icon: "material-symbols:edit-square-outline",
      description: "Chat with the AI to help you edit your form.",
      routes: ["/editor"],
    },
    {
      name: "Publish Form",
      id: "publish",
      type: "action",
      icon: "material-symbols:publish-outline",
      description: "Publish the most recent version of your form.",
      routes: ["/editor"],
    },
    {
      name: "Preview Form",
      id: "preview",
      type: "action",
      icon: "material-symbols:visibility-outline",
      description: "Preview your form as a user would see it.",
      routes: ["/editor"],
    },
    // {
    //   name: "Edit Theme",
    //   id: "theme",
    //   type: "action",
    //   aliases: ["style", "appearance"],
    //   icon: "material-symbols:palette-outline",
    //   description: "Edit the theme of your form.",
    //   routes: ["/editor"],
    // },
    // {
    //   name: "Add Elements",
    //   id: "elements",
    //   type: "app",
    //   aliases: ["insert", "add", "content", "inputs"],
    //   icon: "material-symbols:add-circle-outline",
    //   description: "Add elements to your form.",
    //   routes: ["/editor"],
    // },
    // {
    //   name: "Form Flow",
    //   id: "flow",
    //   type: "action",
    //   aliases: ["flow", "navigation", "pages", "order"],
    //   icon: "material-symbols:format-list-bulleted-square-outline",
    //   description: "Edit the flow of your form's pages.",
    //   routes: ["/editor"],
    // },
    // {
    //   name: "Edit Logic",
    //   id: "logic",
    //   type: "action",
    //   aliases: ["logic", "rules", "conditions", "conditional"],
    //   icon: "material-symbols:code-outline",
    //   description: "Edit the logic of your form.",
    //   routes: ["/editor"],
    // },
    {
      name: "Form Results",
      id: "results",
      type: "action",
      icon: "material-symbols:data-object-outline",
      description: "View the results of your form.",
      routes: ["/editor"],
    },
    {
      name: "History",
      id: "history",
      type: "action",
      aliases: ["revision", "undo", "revert"],
      icon: "material-symbols:history-outline",
      description: "View the history of your form.",
      routes: ["/editor"],
    },
    {
      name: "New Form",
      id: "new",
      aliases: ["create form"],
      type: "app",
      icon: "material-symbols:add-circle-outline",
      description: "Create a new Form.",
      routes: ["*"],
    },
    {
      name: "Dashboard",
      id: "dashboard",
      type: "action",
      aliases: ["home"],
      icon: "material-symbols:dashboard-2-outline",
      description: "Navigate to your FormKit Dashboard.",
      routes: ["*"],
    },
    {
      name: "Open Saved Form",
      id: "open",
      type: "app",
      icon: "material-symbols:open-in-new-outline",
      description: "Open an existing form.",
      routes: ["*"],
    },
    {
      name: "Select Team",
      id: "teams",
      type: "action",
      icon: "material-symbols:people-outline",
      description: "Select from your available FormKit Teams.",
      routes: ["*"],
    },
  ]);

  const isLargeScreen = computed(() => {
    return viewportWidth.value >= 768;
  });

  if (getCurrentInstance()) {
    onMounted(() => {
      viewportWidth.value = document.documentElement.clientWidth;
      window.addEventListener("resize", () => {
        viewportWidth.value = document.documentElement.clientWidth;
      });
    });
  }

  const canEditForm = computed(() => {
    return viewportWidth.value >= 1200;
  });

  function displayError(message: string, duration = 5000) {
    const error = errors.value.find((error) => error.message === message);
    if (error) {
      clearTimeout(error.timeout);
      error.timeout = setTimeout(() => {
        errors.value = errors.value.filter((e) => e !== error);
      }, duration);
      error.flash = true;
      setTimeout(() => {
        error.flash = false;
      }, 250);
    } else {
      const error: Message = { message, timeout: null, flash: false };
      error.timeout = setTimeout(() => {
        errors.value = errors.value.filter((e) => e.message !== error.message);
      }, duration);
      errors.value.push(error);
    }
  }
  function displaySuccess(message: string, duration = 4000) {
    const success = successes.value.find(
      (success) => success.message === message
    );
    if (success) {
      clearTimeout(success.timeout);
      success.timeout = setTimeout(() => {
        successes.value = successes.value.filter((e) => e !== success);
      }, duration);
      success.flash = true;
      setTimeout(() => {
        success.flash = false;
      }, 250);
    } else {
      const success: Message = { message, timeout: null, flash: false };
      success.timeout = setTimeout(() => {
        successes.value = successes.value.filter(
          (s) => s.message !== success.message
        );
      }, duration);
      successes.value.push(success);
    }
  }

  function remove(message: Message & { type: "success" | "error" }) {
    clearTimeout(message.timeout);
    if (message.type === "error") {
      errors.value = errors.value.filter(
        (error) => error.message !== message.message
      );
    } else {
      successes.value = successes.value.filter(
        (success) => success.message !== message.message
      );
    }
  }

  const messages = computed<Array<Message & { type: "success" | "error" }>>(
    (): Array<Message & { type: "success" | "error" }> => {
      return [
        ...errors.value.map((error) => ({ ...error, type: "error" })),
        ...successes.value.map((success) => ({ ...success, type: "success" })),
      ] as Array<Message & { type: "success" | "error" }>;
    }
  );

  return {
    formModalOpen,
    displayError,
    displaySuccess,
    errors,
    successes,
    remove,
    showSubscribeModal,
    showRateLimitedModal,
    messages,
    viewportWidth,
    isLargeScreen,
    canEditForm,
    showPromptHistory,
    editorIsNavigating,
    editorActiveSidebarTab,
    commandPaletteOpen,
    commandPaletteMode,
    commandPaletteCommands,
    navigationFallbackTimer,
    editorActivePageId,
    previewRenderQueue,
    editorCanvasOpen,
    editorHistoryOpen,
    editorPreviewOpen,
    dragging,
    activeElementControls,
  };
});
