import type {
  FormStore,
  FormTheme,
  History,
  ResolvedHistory,
  Pages,
  FormNode,
} from "~/types";
import type { FormKitSchemaFormKit, FormKitSchemaNode } from "@formkit/core";
import gsap from "gsap";
import { Flip } from "gsap/Flip";

import { cloneAny, token } from "@formkit/utils";
import type { Reactive, WritableComputedRef } from "vue";

export function useCurrentForm() {
  const formStore = useFormStore();
  const id = computed(() => {
    const id = useRoute().params.id;
    if (typeof id === "string") {
      return id;
    }
  });
  return computed(() => (id.value ? formStore.forms[id.value] : null));
}

/**
 * Creates a form schema placeholder for a given input.
 * @param id - The id of the form
 * @returns
 */
export function createStore(id: string): FormStore {
  const store: Reactive<FormStore> = reactive({
    id,
    title: "",
    schema: [] as FormNode[],
    theme: {
      layout: "minimal",
      bgColorIntensity: "mediumStrong",
      bgImageEnabled: false,
      bgImageUrl: "",
      bgImageThumbUrl: "",
      bgImageBlurHash: "",
      bgImageOffset: { x: 50, y: 50 },
      bgImageBrightness: 100,
      bgImageTransparency: 12, // inverted
      bgImageCover: false,
      bgFilter: "none",
      bgFilterIntensity: 100,
      bgBlur: 10,
      colorThemeId: "blue",
      formFontFamily: "",
      formFontSizeBoost: 0,
      formMaxWidth: 600,
      formInputStyle: "rounded",
      formInputSize: "md",
      formInputSpacing: 1.2,
      formBgTransparency: 8, // inverted
      formBgBlur: true,
      formBgShadow: true,
      formKitBranding: true,
    } as FormTheme,
    earlyInputBuffer: {},
    history: [] as History[],
    loading: false,
    publishing: false,
    inlineEditorIsOpen: false,
    inlineEditorIsFocused: false,
    activeIdx: "",
    threshold: 0,
    totalThreshold: 0,
    pages: computed((): Pages => formNodesToPages(store.schema)),
    publish: async () => {
      store.loading = true;
      store.publishing = true;
      const { id } = await $fetch<{ id: string }>(`/api/forms/${store.id}`, {
        method: "put",
        body: {
          publish: true,
        },
      });
      // Find the matching history entry and update its published status
      const matchingHistoryIndex = store.history.findIndex(
        (entry) => "id" in entry && entry.id === id
      );
      if (matchingHistoryIndex !== -1) {
        store.history[matchingHistoryIndex].published = true;
      }
      store.loading = false;
      store.publishing = false;
    },
    isPublished: computed((): boolean => {
      return !!store.history[store.history.length - 1]?.published;
    }),
    schemaPages: computed({
      get: (): Pages => {
        return store.pages || [];
      },
      set: (pages) => {
        const formSchema = pages.map((page) => page.schema) as FormNode[][];
        const allPageBreaks = store.schema.filter(
          (node) => node.type === "page_break"
        );
        // save new schema value
        for (let x = 0; x < allPageBreaks.length; x++) {
          formSchema[x].unshift(allPageBreaks[x]);
        }
        let fullSchema: FormNode[] = formSchema.flat();
        applyCurrentPositions(fullSchema);
        store.schema = flattenSchema(fullSchema);
        sortSchema(store.schema);
        structureSchema(store.schema);

        //get the current state of the inputs on the canvas
        // let animationState: any = Flip.getState(
        //   "#editor-canvas .formkit-outer"
        // );

        // // animate the changes
        // requestAnimationFrame(() => {
        //   if (!animationState) return;
        //   Flip.from(animationState, {
        //     duration: 0.33,
        //     ease: "elastic.out(0.075,0.11)",
        //     nested: true,
        //     simple: true,
        //     absolute: true,
        //     absoluteOnLeave: true,
        //     clearProps: "transform",
        //     onComplete: () => {
        //       gsap.set("#editor-canvas .formkit-outer", {
        //         clearProps: true,
        //       });
        //       animationState = null;
        //     },
        //   });
        // });
      },
    }) as WritableComputedRef<Pages>,
    removeByIndex: (idx: string) => {
      const node = getByIdx(store.schema, idx);
      if (node) {
        remove(store.schema, node);
      }
    },
    updateSchemaProperty: (idx: string, property: string, value: any) => {
      let formattedValue = value;
      let formattedProperty = `formatted${property[0].toUpperCase()}${property.slice(
        1
      )}`;
      if (
        typeof value === "string" &&
        value.startsWith("<p>") &&
        value.endsWith("</p>") &&
        typeof window !== "undefined"
      ) {
        // Create a temporary DOM element to parse the HTML
        const tempElement = document.createElement("div");
        tempElement.innerHTML = value;

        // Find all reference spans and replace them with placeholders
        const references = tempElement.querySelectorAll(
          'span[data-type="reference"]'
        );
        const referenceNames: string[] = [];
        references.forEach((span, index) => {
          const name = span.getAttribute("data-id")?.replace("node:", "") || "";
          referenceNames.push(name);
          span.replaceWith(`{{REFERENCE_${index}}}`);
        });

        // Get the text content and escape double quotes
        let textContent = tempElement.textContent || "";
        textContent = textContent.replace(/"/g, '\\"');

        // Replace placeholders with actual names
        referenceNames.forEach((name, index) => {
          textContent = textContent.replace(
            `{{REFERENCE_${index}}}`,
            `" + $formData.${name} + "`
          );
        });

        value = `$: "${textContent}"`;
      }
      const editedNode = getByIdx(store.schema, idx);
      if (editedNode) {
        editedNode[property] = value;
        if (typeof formattedValue === "string") {
          editedNode[formattedProperty] = formattedValue;
        }
      } else {
        console.warn(`Node with idx "${idx}" not found`);
      }
    },
  });
  let timeout: NodeJS.Timeout;

  const partsWorthSaving = computed(() => {
    return JSON.stringify({
      title: store.title,
      schema: store.schema,
      theme: store.theme,
    });
  });

  watch(partsWorthSaving, () => {
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      try {
        if (store.loading || !store.id) return;
        await saveHistory(store as FormStore, {});
      } catch (err) {
        useSystemStore().displayError(
          "There was an error auto-saving your form."
        );
      }
    }, 2000);
  });

  return store;
}

/**
 * Either saves the history immediately or returns a save handler you can call later (optimistically displays the state).
 * @param state - The history state to save
 * @param getSaveHandler - Whether to return a save handler or not
 */
export function saveHistory(
  store: FormStore,
  history: History,
  getSaveHandler: true
): (data?: Record<string, any>) => Promise<ResolvedHistory>;
export function saveHistory(
  store: FormStore,
  history: History
): Promise<ResolvedHistory>;
export function saveHistory(
  store: FormStore,
  history: History,
  getSaveHandler = false
):
  | Promise<ResolvedHistory>
  | ((data?: Record<string, any>) => Promise<ResolvedHistory>) {
  const reactiveHistory = reactive(history);
  store.history.push(reactiveHistory);
  async function saveHistory(
    data: Record<string, any> = {}
  ): Promise<ResolvedHistory> {
    const res = await $fetch<ResolvedHistory>(`/api/forms/${store.id}`, {
      method: "put",
      body: {
        title: store.title,
        history: reactiveHistory,
        schema: store.schema,
        theme: store.theme,
        ...data,
      },
    });
    (reactiveHistory as ResolvedHistory).id = res.id;
    (reactiveHistory as ResolvedHistory).description = res.description;
    (reactiveHistory as ResolvedHistory).page_count = res.page_count;
    (reactiveHistory as ResolvedHistory).created_at = res.created_at;
    return reactiveHistory as ResolvedHistory;
  }
  return getSaveHandler ? saveHistory : saveHistory();
}

export async function saveAction(store: FormStore, content: string) {
  saveHistory(store, {
    user_prompt: content,
  });
}

export async function generateSuggestion(
  store: FormStore,
  node: Omit<FormNode, "idx">
) {
  const inputType = node.inputType;
  try {
    await nextTick();
    const stream = await $fetch<ReadableStream<Uint8Array>>(
      `/api/forms/${store.id}/inputs`,
      {
        method: "POST",
        body: {
          prompt: store.history[store.history.length - 1].user_prompt,
          ascii_outline: asciiOutline(store.pages),
          input_type: inputType,
        },
        responseType: "stream",
      }
    );

    // Seems that if applyCurrentPositions is called before this stream is read,
    // the node will have an idx property. This causes it to hang in its
    // placeholder state so I am setting it to an empty string to prevent this.
    await readToolStream(stream, {
      inputType,
      node,
    });
  } catch (err) {
    console.error(err);
    remove(store.schema, node);
    store.history.pop();
    return handleBackendError(err);
  }
}

async function streamThresholds(
  store: FormStore,
  stream: ReadableStream<Uint8Array>
) {
  let thresholds: { thresholds: number[] } = { thresholds: [] };
  for await (const chunk of jsonReader(stream.getReader())) {
    thresholds = chunk;
  }
  if (Array.isArray(thresholds.thresholds)) {
    const value = thresholds.thresholds.reduce((a, b) => a + b, 0);
    if (isFinite(value)) {
      store.threshold = value;
      store.totalThreshold += value;
    }
  }
}
