import type {
  CodeOptions,
  FormKitSchemaNode,
  FormNode,
  FormStore,
  Pages,
} from "../types";
import { eq, cloneAny, kebab, camel, token, extend } from "@formkit/utils";
import { components, inputTypes } from "../prompts/inputs";
import type { FormKitSchemaFormKit } from "@formkit/core";

/**
 * Breaks a pos like '1.2' into an array of integers [1, 2]
 * @param pos - A string pos like '1.2'
 * @returns
 */
function createDelta(idx: string) {
  return idx.split(".").map((k) => parseFloat(k));
}

/**
 * Adds two arrays of numbers together. If the second array is more precise than the first, its extra values are dropped.
 * @param a - The first array
 * @param b - The second array
 * @returns
 */
function addDeltas(a: number[], b?: number[]) {
  if (!b) return a;
  return a.map((k, i) => k + (b[i] ?? 0));
}

/**
 * Given a idx like 1.2.3 it will create a delta for the least significant part. So if the idx is 1.2.3
 * and the delta provided is -.01 it will return [0, 0, -.01]
 * @param idx - The index to create a least significant delta for
 */
export function createLeastSignificantDelta(idx: string, delta: number) {
  const newDelta = new Array(idx.split(".").length).fill(0);
  newDelta[newDelta.length - 1] = delta;
  return newDelta;
}

/**
 * Checks if delta A is less than (-1) the same (0) or more (1) than delta b.
 * @param a - The first delta to check
 * @param b - The second delta to check
 * @returns
 */
export function compareDeltas(a: number[], b: number[]): -1 | 0 | 1 {
  for (let i = 0; i < Math.max(a.length, b.length); i++) {
    if (a[i] === undefined && b[i] === undefined) return 0;
    if (a[i] === undefined) return -1;
    if (b[i] === undefined) return 1;
    if (a[i] < b[i]) return -1;
    if (a[i] > b[i]) return 1;
  }
  return 0;
}

/**
 * Performs an in-place flatten of the schema.
 * @param schema - The schema to flatten
 * @returns
 */
export function flattenSchema<T extends { children?: T }[]>(schema: T): T {
  let i = 0;
  while (schema[i]) {
    const node = schema[i];
    if (node.children && node.children.length > 0) {
      schema.splice(i + 1, 0, ...node.children.splice(0, node.children.length));
      continue;
    }
    i++;
  }
  return schema;
}

/**
 * Sorts and structures the schema from flat storage into a nested
 * tree structure with `children`. It does this in 02 steps without
 * any recursion.
 * @param schema - The schema to sort
 */
export function sortSchema<T extends { idx: string; delta?: number[] }>(
  schema: T[]
): T[] {
  // 1. First we pre-sort all the nodes
  schema.sort((a, b) => {
    const keyA = addDeltas(createDelta(a.idx), a.delta);
    const keyB = addDeltas(createDelta(b.idx), b.delta);
    return compareDeltas(keyA, keyB);
  });
  return schema;
}

/**
 * Takes a flat schema structure and returns a structured schema. Operates in-place.
 * @param schema - Pre-sorted schema
 * @returns
 */
export function structureSchema<T extends { idx: string; children?: T[] }>(
  schema: T[]
): T[] {
  // 1. First we sort the schema
  sortSchema(schema);
  // 2. Then we indent the nodes based on their order
  let i = 0;
  const stack = [];
  root: while (schema[i]) {
    const node = schema[i];
    while (stack.length) {
      const last = stack[stack.length - 1];
      if (node.idx.startsWith(last.idx + ".")) {
        if (last.children === undefined) last.children = [];
        last.children.push(node);
        stack.push(node);
        schema.splice(i, 1); // delete it from the root
        // Don't increment i, we want to check the same index again since
        // we removed this element from the root.
        continue root;
      }
      // If the node is not a child of the last node in the stack
      stack.pop();
    }
    // If we get here, the node is a root node — we just leave it alone
    // since we are doing all modifications in-place.
    stack.push(node);
    i++;
  }
  return schema;
}

/**
 * Recursively maps a schema with a callback that can modify each node.
 * @param schema - The schema to map
 * @param cb - The callback to call on each node
 * @returns
 */
export function mapSchema<T extends { children?: T[] }>(
  schema: T[],
  cb: (node: T) => T | false
): T[] {
  const result: T[] = [];
  schema.forEach((node) => {
    const newNode = cb(node);
    if (newNode !== false) result.push(newNode);
    if (
      typeof node === "object" &&
      "children" in node &&
      Array.isArray(node.children) &&
      typeof newNode === "object" &&
      "children" in newNode
    ) {
      newNode.children = mapSchema(node.children, cb);
    }
  });
  return result;
}

/**
 * Shifts the position of a node in the schema by changing its key and restructuring.
 * @param node - The node to change the position of
 * @param pos - The new position
 */
export function changePos(schema: FormNode[], node: FormNode, delta: 1 | -1) {
  flattenSchema(schema);
  const index = schema.indexOf(node);
  if (index === -1) return;
  const newIndex = index + delta;
  const target = schema[newIndex];
  if (target) {
    const targetKeys = createDelta(target.idx);
    const currentKeys = createDelta(node.idx);
    targetKeys.pop();
    currentKeys.pop();
    if (eq(targetKeys, currentKeys)) {
      const targetKey = target.idx;
      target.idx = node.idx;
      node.idx = targetKey;
    }
  }
  structureSchema(schema);
}

/**
 * Get a given node by the index.
 * @param schema - The schema to get the node from
 * @param idx - The idx of the node to get
 * @returns
 */
export function getByIdx(
  schema: FormNode[],
  idx: string
): FormNode | undefined {
  flattenSchema(schema);
  sortSchema(schema);
  const node = schema.find((node) => node.idx === idx);
  structureSchema(schema);
  return node;
}

/**
 * Removes a node from the schema in-place.
 * @param schema - The schema to remove the node from
 * @param node - The node to remove
 * @returns
 */
export function remove(schema: FormNode[], node: FormNode) {
  flattenSchema(schema);
  const index = schema.indexOf(node);
  if (index === -1) return;
  schema.splice(index, 1);
  structureSchema(schema);
}

/**
 * Performs a depth first search for the parent of a node in the schema.
 * @param schema - The schema to get the parent from
 * @param node - The node to get the parent of
 * @returns
 */
export function getParent<T extends { idx: string; children?: T[] }>(
  schema: T[],
  node: T
): T[] | false {
  for (let i = 0; i < schema.length; i++) {
    const child = schema[i];
    if (node === child) return schema;
    if (child.children) {
      const parent = getParent(child.children, node);
      if (parent) return parent;
    }
  }
  return false;
}

/**
 * Applies the current positions to the schema in-place.
 * @param schema - The schema to apply the positions to
 * @returns
 */
export function applyCurrentPositions<
  T extends { idx: string; children?: T[]; delta?: number[] }
>(schema: T[], depth: string = ""): T[] {
  for (let i = 0; i < schema.length; i++) {
    const node = schema[i];
    node.idx = `${depth !== "" ? `${depth}.` : ""}${i + 1}`;
    if (node.delta) {
      delete node.delta;
    }
    if (node.children) {
      applyCurrentPositions(node.children, node.idx);
    }
  }
  return schema;
}

/**
 * Recursively looks for a name in the schema.
 * @param schema - The schema to check if the name exists in
 * @param name - The name to check for
 * @returns
 */
function nameExists<T extends { children?: T[] }>(
  schema: T[],
  name: string
): boolean {
  return schema.some((node) => {
    if ("name" in node && node.name === name) {
      return true;
    }
    if (node.children) {
      return nameExists(node.children, name);
    }
    return false;
  });
}

/**
 * Modifies an input by name in the schema in-place.
 * @param schema - The schema to modify the input in
 * @param name - The name of the input to modify
 * @param modificationData - The data to modify the input with
 * @returns
 */
export function modifyInputByName(
  schema: FormNode[],
  name: string,
  modificationData: Partial<FormNode>
): boolean {
  const nodesWithChildren: FormNode[] = [];

  for (const node of schema) {
    if (node.children) {
      nodesWithChildren.push(node);
    }
    if ("name" in node && node.name === name) {
      Object.assign(node, extend(node, modificationData));
      return true;
    }
  }

  for (const node of nodesWithChildren) {
    if (modifyInputByName(node.children!, name, modificationData)) {
      return true;
    }
  }

  return false;
}

/**
 * Recursively looks for a name in the schema.
 * @param schema - The schema to check if the name exists in
 * @param name - The name to check for
 * @returns
 */
export function getInputByName(
  schema: FormNode[],
  name: string
): FormNode | undefined {
  for (const node of schema) {
    if ("name" in node && node.name === name) {
      return node;
    }
    if (node.children) {
      const found = getInputByName(node.children, name);
      if (found) return found;
    }
  }
  return undefined;
}

/**
 * Removes inputs by name in-place.
 * @param schema - The schema to remove the inputs from
 * @param name - The name of the inputs to remove
 * @returns
 */
export function removeInputsByName(schema: FormNode[], name: string): boolean {
  for (let i = 0; i < schema.length; i++) {
    const node = schema[i];
    if ("name" in node && node.name === name) {
      schema.splice(i, 1);
      return true;
    }
    if (node.children) {
      if (removeInputsByName(node.children, name)) {
        return true;
      }
    }
  }
  return false;
}

/**
 * Duplicates a node in the schema in-place.
 * @param schema - The schema to duplicate the node in
 * @param node - The node to duplicate
 */
export function duplicate<T extends { idx: string; children?: T[] }>(
  schema: T[],
  node: T
): T[] {
  const parent = getParent(schema, node);
  if (!parent) return schema;
  const cloned = cloneAny(node);
  let unique = "copy";
  let i: number | "" = "";
  if ("name" in cloned) {
    while (nameExists(schema, `${cloned.name}_${unique}${i}`)) {
      i = i === "" ? 2 : i + 1;
    }
  }
  mapSchema([cloned], (node) => {
    if ("key" in node) node.key += `-${unique}${i}`;
    if ("name" in node) node.name += `_${unique}${i}`;
    if ("label" in node) node.label += ` ${unique}${i ? " " + i : ""}`;
    return node;
  });
  const index = parent.indexOf(node);
  parent.splice(index + 1, 0, cloned);
  return applyCurrentPositions(schema);
}

/**
 * Replaces a node in the schema by idx.
 * @param schema - Schema to replace the node in
 * @param node - The node to replace
 * @param depth - The depth of the node
 */
export function replaceSchemaByIdx(
  schema: FormNode[],
  node: FormNode
): boolean {
  flattenSchema(schema);
  const index = schema.findIndex((n) => String(n.idx) === String(node.idx));
  if (index === -1) return false;
  schema[index] = node;
  structureSchema(schema);
  return true;
}

/**
 * Replaces a node in the schema by idx.
 * @param schema - Schema to replace the node in
 * @param node - The node to replace
 * @param depth - The depth of the node
 */
export function extendSchemaByIdx(schema: FormNode[], node: FormNode): boolean {
  flattenSchema(schema);
  const index = schema.findIndex((n) => String(n.idx) === String(node.idx));
  if (index === -1) return false;
  Object.assign(schema[index], node);
  structureSchema(schema);
  return true;
}

/**
 * Inserts a schema at the appropriate position.
 * @param schema - The schema to insert into
 * @param node - The node to insert
 */
export function insertNode(schema: FormNode[], node: FormNode) {
  flattenSchema(schema);
  sortSchema(schema);
  const nodeDelta = addDeltas(createDelta(node.idx), node.delta);
  const index = schema.findIndex(
    (input) =>
      compareDeltas(
        addDeltas(createDelta(input.idx), input.delta),
        nodeDelta
      ) !== -1
  );
  if (index !== -1) {
    schema.splice(index, 0, node);
    const deltaPush = createLeastSignificantDelta(node.idx, 0.25);
    for (let i = index + 1; i < schema.length; i++) {
      const n = schema[i];
      n.delta = n.delta ? addDeltas(n.delta, deltaPush) : deltaPush;
    }
  } else {
    schema.push(node);
  }
  structureSchema(schema);
}

export function applyMoves(
  schema: FormNode[],
  moves: Array<{ old_idx: string; new_idx: string }>
) {
  if (!moves.length) return;
  flattenSchema(schema);
  sortSchema(schema);
  // // First get a map of the old indexes to the nodes we do
  // // this since all the moves are based on the old index and if
  // // the lookups happen progressively the node idx could have changed
  // // by the time we get to them.
  const idxMappedNodes = moves.reduce((map, move) => {
    const node = schema.find((node) => node.idx === move.old_idx);
    if (node) {
      map[move.old_idx] = node;
    }
    return map;
  }, {} as Record<string, FormNode>);
  moves.forEach((move) => {
    const node = idxMappedNodes[move.old_idx];
    if (node) {
      node.idx = move.new_idx;
    }
  });
  structureSchema(schema);
  applyCurrentPositions(schema);
}

/**
 * Props that should be required.
 * @param type - The type of prop.
 * @returns
 */
export function requiredProps(type: string): string[] {
  switch (type) {
    case "currency":
      return ["currency"];
  }
  return [];
}

/**
 * Props that should be required.
 * @param type - The type of prop.
 * @returns
 */
export function silentProps(type: string): string[] | undefined {
  switch (type) {
    case "datepicker":
      return ["format"];
    default:
      return undefined;
  }
}

/**
 * Given a generated schema node, make sure the types are correct and perform any necessary corrections.
 * @param node - The node to correct the schema for
 */
export function correctSchema(node: Record<string, string>): FormKitSchemaNode {
  if (!node.type) {
    node.type = "text";
  } else if (
    !components.includes(node.type as any) &&
    !inputTypes.includes(node.type)
  ) {
    node.type = "text";
  }

  if (node.type === "datepicker" && !node.format) {
    node.format = "long";
  }

  return node as FormKitSchemaNode;
}

export const hiddenProps = [
  "cols",
  "idx",
  "children",
  "key",
  "outer-class",
  "form-class",
  "validation",
  "help",
  "delta",
  "$formkit",
  "dataActive",
  "data-active",
];
export const boundProps = [
  "options",
  "sequence",
  "format",
  "actions",
  "disabled",
];
/**
 * Given some schema, generate a code block for it.
 * @param schema - The schema to generate code for
 */
export function schemaToCode(
  schema: FormNode[],
  options: CodeOptions,
  lineIndent = ""
) {
  let code = "";
  for (const node of schema) {
    code += `${lineIndent}<FormKit`;
    code += "\n";
    lineIndent += "  ";
    for (const prop in node) {
      let value = node[prop];
      if (value === undefined) continue;
      const formattedProp = kebab(prop).replaceAll("_", "-");
      if (
        options.includes(formattedProp as any) ||
        !hiddenProps.includes(formattedProp) ||
        (formattedProp.endsWith("class") && options.includes("class"))
      ) {
        if (boundProps.includes(prop)) {
          code += `${lineIndent}:${formattedProp}="${formatBoundProp(
            value,
            "  "
          )}"\n`;
        } else {
          code += `${lineIndent}${formattedProp}="${value}"\n`;
        }
      }
    }
    lineIndent = lineIndent.slice(0, -2);
    if (node.children && node.children.length) {
      code += `${lineIndent}>\n`;
      code += schemaToCode(node.children, options, lineIndent + "  ");
    }
    if (node.children && node.children.length) {
      code += `${lineIndent}</FormKit>\n`;
    } else {
      code += `${lineIndent}/>\n`;
    }
  }
  return code;
}

/**
 * Search for a given node by a given target property in a given schema.
 * @param schema - The schema to search
 * @param node - The node to find
 * @param target - The target to search for
 * @returns
 */
export function findNodeInSchema(
  schema: FormNode[],
  node: FormNode,
  target = "key"
): FormKitSchemaNode | null {
  for (const child of schema) {
    if (!("type" in child)) continue;
    if (child[target] === node[target]) {
      return child as FormKitSchemaNode;
    }
    if (child.children?.length) {
      const found = findNodeInSchema(child.children, node, target);
      if (found) return found;
    }
  }
  return null;
}

type JSONValue = string | number | boolean | null | JSONObject | JSONArray;
type JSONObject = { [key: string]: JSONValue };
type JSONArray = JSONValue[];

function formatBoundProp(
  data: JSONValue,
  additionalIndent: string = ""
): string {
  // Helper function to escape quotes for Vue prop
  const escapeForProp = (str: string): string => {
    return str.replace(/'/g, "\\'").replace(/"/g, '\\"');
  };

  // Helper function to stringify value
  const stringifyValue = (val: JSONValue): string => {
    if (typeof val === "string") {
      return `'${escapeForProp(val)}'`;
    } else if (typeof val === "object" && val !== null) {
      if (Array.isArray(val)) {
        return `[${val.map(stringifyValue).join(", ")}]`;
      } else {
        return `{${Object.entries(val)
          .map(([k, v]) => `'${escapeForProp(k)}': ${stringifyValue(v)}`)
          .join(", ")}}`;
      }
    }
    return JSON.stringify(val);
  };

  // Main stringify function with indentation
  const stringify = (obj: JSONValue, indent: number = 0): string => {
    const totalIndent = additionalIndent + " ".repeat(indent);
    if (Array.isArray(obj)) {
      if (obj.length === 0) return "[]";
      let result = "[\n";
      obj.forEach((item, index) => {
        result += totalIndent + "  " + stringify(item, indent + 2);
        if (index < obj.length - 1) result += ",";
        result += "\n";
      });
      result += totalIndent + "]";
      return result;
    } else if (typeof obj === "object" && obj !== null) {
      if (Object.keys(obj).length === 0) return "{}";
      let result = "{\n";
      Object.entries(obj).forEach(([key, value], index) => {
        result +=
          totalIndent +
          "  " +
          `'${escapeForProp(key)}': ${stringify(value, indent + 2)}`;
        if (index < Object.keys(obj).length - 1) result += ",";
        result += "\n";
      });
      result += totalIndent + "}";
      return result;
    }
    return stringifyValue(obj);
  };

  // Perform the formatting
  const formattedProp = stringify(data);

  // Add the additional indentation after each newline
  return formattedProp.replace(/\n/g, "\n" + additionalIndent);
}

/**
 * Exports a schema to a FormKit schema.
 * @param schema - FormNode schema to export
 * @param options - The options to export
 * @returns
 */
export function exportSchema(schema: FormNode[], options?: CodeOptions) {
  return schema
    .map((child) => {
      if (child.type) {
        const node: FormKitSchemaFormKit = {
          $formkit: child.type,
          ...cleanSchemaNode(child, options),
        };
        if (child.children?.length) {
          node.children = exportSchema(child.children, options);
        }
        return node;
      }
    })
    .filter((value) => !!value);
}

/**
 * Cleans a given schema node.
 * @param schema - The schema to clean
 * @param options - The options to clean
 * @returns
 */
export function cleanSchemaNode(
  schema: FormNode,
  options?: CodeOptions
): Omit<FormNode, "idx" | "children" | "delta" | "type" | "$formkit"> {
  const node: Partial<FormNode> = {};
  for (const prop in schema) {
    node[camel(prop.replaceAll("_", "-"))] = schema[prop];
  }
  for (const prop of hiddenProps) {
    if (
      (prop.endsWith("class") && !options?.includes("class")) ||
      (!prop.endsWith("class") &&
        !options?.includes(prop as any) &&
        prop !== "children")
    ) {
      delete node[camel(prop.replaceAll("_", "-"))];
    }
  }
  return node;
}

/**
 * Removes all keys from a given schema.
 * @param schema - The schema to remove keys from
 * @returns
 */
export function removeAllKeys(schema?: FormNode[]): FormNode[] {
  if (!schema) return [];
  for (let i = 0; i < schema.length; i++) {
    delete schema[i].key;

    if (Array.isArray(schema[i].children)) {
      removeAllKeys(schema[i].children);
    }
  }
  return schema;
}

/**
 * Post processes a form schema to make sure everything is properly structured.
 * @param store - The form store
 */
export function postProcessForm(store: FormStore) {
  structureSchema(store.schema);
  // Ensure the form schema starts with an initial page_break
  if (store.schema.length === 0 || store.schema[0].type !== "page_break") {
    const initialPageBreak: FormNode = {
      type: "page_break",
      idx: "0",
      key: token(),
      name: token(),
      content: store.title,
    };

    store.schema.unshift(initialPageBreak);
  }

  let currentIdx = 0;

  for (let i = 0; i < store.schema.length; i++) {
    const node = store.schema[i];
    node.idx = currentIdx.toString();
    currentIdx++;

    if (node.type === "page_break") {
      // Un-nest any children of page breaks
      if (node.children) {
        const childrenToInsert = node.children.map((child) => {
          child.idx = currentIdx.toString();
          currentIdx++;
          return child;
        });
        store.schema.splice(i + 1, 0, ...childrenToInsert);
        delete node.children;
        i += childrenToInsert.length; // Skip the newly inserted children
      }

      // Add a submit button to the previous page if it doesn't end with one
      if (i > 0 && store.schema[i - 1].type !== "submit") {
        const submitButton: FormNode = {
          type: "submit",
          idx: currentIdx.toString(),
          name: "next_button",
          label: "Next",
          outerClass: "col-span-2",
        };
        store.schema.splice(i, 0, submitButton);
        currentIdx++;
        i++; // Skip the newly inserted submit button
      }
    }
  }

  // Handle the last page
  const pageBreaks = store.schema.filter((node) => node.type === "page_break");
  if (pageBreaks.length > 1) {
    const lastPageBreakIndex = store.schema.lastIndexOf(
      pageBreaks[pageBreaks.length - 1]
    );
    const elementsAfterLastPageBreak = store.schema.slice(
      lastPageBreakIndex + 1
    );

    // Check if the last page only contains a submit button
    if (
      elementsAfterLastPageBreak.length === 1 &&
      elementsAfterLastPageBreak[0].type === "submit"
    ) {
      // Remove the last page break
      store.schema.splice(lastPageBreakIndex, 1);
      // The submit button is now on the previous (now last) page
    } else {
      // Add a submit button to the last page if it doesn't end with one
      if (store.schema[store.schema.length - 1].type !== "submit") {
        const submitButton: FormNode = {
          type: "submit",
          key: token(),
          idx: currentIdx.toString(),
          name: token(),
          label: "Submit",
        };
        store.schema.push(submitButton);
      }
    }
  } else {
    // If there's only one page, ensure it ends with a submit button
    if (store.schema[store.schema.length - 1].type !== "submit") {
      const submitButton: FormNode = {
        type: "submit",
        key: token(),
        idx: currentIdx.toString(),
        name: token(),
        label: "Submit",
      };
      store.schema.push(submitButton);
    }
  }

  // Apply current positions to the schema
  applyCurrentPositions(store.schema);
}

/**
 * Converts form nodes (as stored/returned by the llm) into actual FormKit schema nodes.
 * @param schema - the form nodes to convert
 */
export function formNodesToSchema(schema?: FormNode[]): FormKitSchemaNode[] {
  if (!schema) return [];
  return mapSchema(schema, (sourceNode) => {
    const clonedNode = cloneAny(sourceNode);
    if (!("type" in clonedNode)) return false;
    if ("type" in clonedNode && clonedNode.type === "placeholder") {
      const { type, ...props } = clonedNode;
      return {
        $cmp: "Placeholder",
        props,
      } as unknown as FormNode; // I swear the idx is not needer here yet....
    } else if ("type" in clonedNode && clonedNode.type !== "page_break") {
      clonedNode.$formkit = clonedNode.type;
    }
    if ("$formkit" in clonedNode) {
      if (!("key" in clonedNode) && "name" in clonedNode) {
        clonedNode.key = clonedNode.name;
      }
      clonedNode.__pageId = "$__pageId";
    }
    return clonedNode;
  }) as FormKitSchemaNode[];
}

/**
 * Converts form nodes (as stored/returned by the llm) into actual FormKit schema nodes. Grouped into pages.
 * @param schema - the form nodes to convert
 */
export function formNodesToPages(schema: FormNode[]): Pages {
  const pages: Pages = [];
  let currentPage: FormNode[] = [];
  let currentPageTitle = "Page 1"; // Default title for the first page
  let currentPageId = token();

  for (const node of schema) {
    if (node.type === "page_break") {
      if (currentPage.length > 0) {
        pages.push({
          id: currentPageId,
          title: currentPageTitle,
          schema: formNodesToSchema(currentPage),
        });
      }
      currentPage = [];
      currentPageTitle = node.content;
      currentPageId = node.key;
    } else {
      currentPage.push(node);
    }
  }

  // Add the last page if it's not empty
  if (currentPage.length > 0) {
    pages.push({
      id: currentPageId,
      title: currentPageTitle,
      schema: formNodesToSchema(currentPage),
    });
  }

  return pages;
}

/**
 * Counts the number of pages in a given schema.
 * @param schema - The form nodes to analyze
 * @returns The number of pages in the schema
 */
export function getPageCount(schema: FormNode[]): number {
  let pageCount = 0;

  // Iterate through the schema and count page breaks
  for (const node of schema) {
    if (node.type === "page_break") {
      pageCount++;
    }
  }

  return pageCount;
}
