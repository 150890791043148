import { default as indexf52Q67wpbwMeta } from "/opt/buildhome/repo/pages/[id]/index.vue?macro=true";
import { default as accept_45inviteVEXQD9jHqpMeta } from "/opt/buildhome/repo/pages/accept-invite.vue?macro=true";
import { default as affiliated2AIpnUwuEMeta } from "/opt/buildhome/repo/pages/affiliate.vue?macro=true";
import { default as confirm_45emailrMXGrk0TKkMeta } from "/opt/buildhome/repo/pages/confirm-email.vue?macro=true";
import { default as resultsGpHy4UhotGMeta } from "/opt/buildhome/repo/pages/dashboard/[id]/results.vue?macro=true";
import { default as _91team_93KLp5W6H6ahMeta } from "/opt/buildhome/repo/pages/dashboard/[team].vue?macro=true";
import { default as accountHOghyR6a3UMeta } from "/opt/buildhome/repo/pages/dashboard/account.vue?macro=true";
import { default as create_45teamYsgSXdgFRkMeta } from "/opt/buildhome/repo/pages/dashboard/create-team.vue?macro=true";
import { default as indexSEhdEZkNMjMeta } from "/opt/buildhome/repo/pages/dashboard/index.vue?macro=true";
import { default as indexgvcrPMpHXdMeta } from "/opt/buildhome/repo/pages/dashboard/teams/[slug]/index.vue?macro=true";
import { default as manageQhjBwtrtjaMeta } from "/opt/buildhome/repo/pages/dashboard/teams/[slug]/manage.vue?macro=true";
import { default as _91slug_93qkalPSHmGQMeta } from "/opt/buildhome/repo/pages/dashboard/teams/[slug].vue?macro=true";
import { default as index8eFV37NeRbMeta } from "/opt/buildhome/repo/pages/dashboard/teams/index.vue?macro=true";
import { default as teamsbmugVzYOozMeta } from "/opt/buildhome/repo/pages/dashboard/teams.vue?macro=true";
import { default as dashboard6cxkiptQlsMeta } from "/opt/buildhome/repo/pages/dashboard.vue?macro=true";
import { default as _91pageNumber_93sWNCLXg3TuMeta } from "/opt/buildhome/repo/pages/editor/[id]/history/[historyId]/[pageNumber].vue?macro=true";
import { default as indexjO1UaygmMPMeta } from "/opt/buildhome/repo/pages/editor/[id]/index.vue?macro=true";
import { default as all78BgoDoTRgMeta } from "/opt/buildhome/repo/pages/editor/[id]/preview/all.vue?macro=true";
import { default as indextYJr8D11icMeta } from "/opt/buildhome/repo/pages/editor/[id]/preview/index.vue?macro=true";
import { default as indexCRujvmJybGMeta } from "/opt/buildhome/repo/pages/editor/index.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as logout9C1bpEu0McMeta } from "/opt/buildhome/repo/pages/logout.vue?macro=true";
import { default as logsf8yw9QQw8vMeta } from "/opt/buildhome/repo/pages/logs.vue?macro=true";
import { default as playground_45billingJDEnE3nIrCMeta } from "/opt/buildhome/repo/pages/playground-billing.vue?macro=true";
import { default as pricingQrzbg66BTdMeta } from "/opt/buildhome/repo/pages/pricing.vue?macro=true";
import { default as privacy_45policypnUDKF1OY5Meta } from "/opt/buildhome/repo/pages/privacy-policy.vue?macro=true";
import { default as _91pageNumber_93bi79EbU1Q6Meta } from "/opt/buildhome/repo/pages/screenshots/[id]/[pageNumber].vue?macro=true";
import { default as allZK92NiOqnlMeta } from "/opt/buildhome/repo/pages/screenshots/[id]/all.vue?macro=true";
import { default as terms_45of_45service4Xad5aMSOFMeta } from "/opt/buildhome/repo/pages/terms-of-service.vue?macro=true";
import { default as testnxGb4eh78qMeta } from "/opt/buildhome/repo/pages/test.vue?macro=true";
export default [
  {
    name: "id",
    path: "/:id()",
    meta: indexf52Q67wpbwMeta || {},
    component: () => import("/opt/buildhome/repo/pages/[id]/index.vue")
  },
  {
    name: "accept-invite",
    path: "/accept-invite",
    component: () => import("/opt/buildhome/repo/pages/accept-invite.vue")
  },
  {
    name: "affiliate",
    path: "/affiliate",
    component: () => import("/opt/buildhome/repo/pages/affiliate.vue")
  },
  {
    name: "confirm-email",
    path: "/confirm-email",
    meta: confirm_45emailrMXGrk0TKkMeta || {},
    component: () => import("/opt/buildhome/repo/pages/confirm-email.vue")
  },
  {
    name: dashboard6cxkiptQlsMeta?.name,
    path: "/dashboard",
    meta: dashboard6cxkiptQlsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-id-results",
    path: ":id()/results",
    meta: resultsGpHy4UhotGMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/[id]/results.vue")
  },
  {
    name: "dashboard-team",
    path: ":team()",
    component: () => import("/opt/buildhome/repo/pages/dashboard/[team].vue")
  },
  {
    name: "dashboard-account",
    path: "account",
    meta: accountHOghyR6a3UMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/account.vue")
  },
  {
    name: "dashboard-create-team",
    path: "create-team",
    meta: create_45teamYsgSXdgFRkMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/create-team.vue")
  },
  {
    name: "dashboard",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/dashboard/index.vue")
  },
  {
    name: teamsbmugVzYOozMeta?.name,
    path: "teams",
    component: () => import("/opt/buildhome/repo/pages/dashboard/teams.vue"),
    children: [
  {
    name: _91slug_93qkalPSHmGQMeta?.name,
    path: ":slug()",
    component: () => import("/opt/buildhome/repo/pages/dashboard/teams/[slug].vue"),
    children: [
  {
    name: "dashboard-teams-slug",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/dashboard/teams/[slug]/index.vue")
  },
  {
    name: "dashboard-teams-slug-manage",
    path: "manage",
    component: () => import("/opt/buildhome/repo/pages/dashboard/teams/[slug]/manage.vue")
  }
]
  },
  {
    name: "dashboard-teams",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/dashboard/teams/index.vue")
  }
]
  }
]
  },
  {
    name: "editor-id-history-historyId-pageNumber",
    path: "/editor/:id()/history/:historyId()/:pageNumber()",
    meta: _91pageNumber_93sWNCLXg3TuMeta || {},
    component: () => import("/opt/buildhome/repo/pages/editor/[id]/history/[historyId]/[pageNumber].vue")
  },
  {
    name: "editor-id",
    path: "/editor/:id()",
    meta: indexjO1UaygmMPMeta || {},
    component: () => import("/opt/buildhome/repo/pages/editor/[id]/index.vue")
  },
  {
    name: "editor-id-preview-all",
    path: "/editor/:id()/preview/all",
    meta: all78BgoDoTRgMeta || {},
    component: () => import("/opt/buildhome/repo/pages/editor/[id]/preview/all.vue")
  },
  {
    name: "editor-id-preview",
    path: "/editor/:id()/preview",
    meta: indextYJr8D11icMeta || {},
    component: () => import("/opt/buildhome/repo/pages/editor/[id]/preview/index.vue")
  },
  {
    name: "editor",
    path: "/editor",
    meta: indexCRujvmJybGMeta || {},
    component: () => import("/opt/buildhome/repo/pages/editor/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login4dfr6kRqBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logout9C1bpEu0McMeta || {},
    component: () => import("/opt/buildhome/repo/pages/logout.vue")
  },
  {
    name: "logs",
    path: "/logs",
    meta: logsf8yw9QQw8vMeta || {},
    component: () => import("/opt/buildhome/repo/pages/logs.vue")
  },
  {
    name: "playground-billing",
    path: "/playground-billing",
    component: () => import("/opt/buildhome/repo/pages/playground-billing.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/opt/buildhome/repo/pages/pricing.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/opt/buildhome/repo/pages/privacy-policy.vue")
  },
  {
    name: "screenshots-id-pageNumber",
    path: "/screenshots/:id()/:pageNumber()",
    meta: _91pageNumber_93bi79EbU1Q6Meta || {},
    component: () => import("/opt/buildhome/repo/pages/screenshots/[id]/[pageNumber].vue")
  },
  {
    name: "screenshots-id-all",
    path: "/screenshots/:id()/all",
    meta: allZK92NiOqnlMeta || {},
    component: () => import("/opt/buildhome/repo/pages/screenshots/[id]/all.vue")
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    component: () => import("/opt/buildhome/repo/pages/terms-of-service.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/opt/buildhome/repo/pages/test.vue")
  }
]