<script lang="ts" setup>
import { token } from "@formkit/utils";

const system = useSystemStore();

const {
  editorHistoryOpen,
  commandPaletteOpen,
  commandPaletteMode,
  editorPreviewOpen,
} = storeToRefs(system);

const renderKey = ref(token());

function handleEditorPromptShortcut(e: KeyboardEvent) {
  if (commandPaletteOpen.value) {
    if (e.key === "Escape") {
      commandPaletteOpen.value = false;
    }
  } else if ((e.metaKey || e.ctrlKey) && e.key === "k") {
    e.preventDefault();
    if (!commandPaletteOpen.value) {
      commandPaletteMode.value = null;
      commandPaletteOpen.value = true;
    } else if (commandPaletteMode.value) {
      commandPaletteMode.value = null;
      renderKey.value = token();
    } else {
      commandPaletteOpen.value = false;
    }
  }
}

watch([editorPreviewOpen, editorHistoryOpen], () => {
  if (editorPreviewOpen.value || editorHistoryOpen.value) {
    commandPaletteOpen.value = false;
  }
});

onUnmounted(() => {
  editorHistoryOpen.value = false;
  commandPaletteOpen.value = false;

  window.removeEventListener("keydown", handleEditorPromptShortcut);
});

onMounted(() => {
  window.addEventListener("keydown", handleEditorPromptShortcut);
});
</script>

<template>
  <div
    v-if="commandPaletteOpen"
    class="absolute inset-0 z-[99] pointer-events-none"
  >
    <div class="absolute inset-0 flex items-center justify-center p-4">
      <CommandPalette
        :key="renderKey"
        class="pointer-events-auto w-[90vw] max-w-[500px]"
      />
    </div>
  </div>
</template>
