import type { FormKitColorTheme } from "@/types";

export function getThemeById(
  id: string,
  themeSet: { label: string; value: FormKitColorTheme }[]
): FormKitColorTheme | undefined {
  if (!id || !themeSet) {
    return undefined;
  }
  const match = themeSet.find((theme) => theme.value.id === id);
  return match ? match.value : undefined;
}

export const monoThemes = [
  {
    label: "It's not a Phase",
    value: {
      id: "black",
      temp: "rgba(31, 31, 33, 1)",
      accent: "rgba(33, 33, 33, 1)",
    },
  },
  {
    label: "Like a Steel Trap",
    value: {
      id: "gray",
      temp: "rgba(28, 36, 41, 1)",
      accent: "rgba(73, 81, 97, 1)",
    },
  },
  {
    label: "Trust-Me Blue",
    value: {
      id: "blue",
      temp: "rgba(25, 33, 64, 1)",
      accent: "rgba(39, 81, 233, 1)",
    },
  },
  {
    label: "Skyline Drive",
    value: {
      id: "sky",
      temp: "rgba(26, 46, 80, 1)",
      accent: "rgba(55, 137, 252, 1)",
    },
  },
  {
    label: "Ocean Breeze",
    value: {
      id: "ocean",
      temp: "rgba(29, 59, 60, 1)",
      accent: "rgba(46, 161, 179, 1)",
    },
  },
  {
    label: "Emerald City",
    value: {
      id: "emerald",
      temp: "rgba(16, 33, 23, 1)",
      accent: "rgba(44, 178, 99, 1)",
    },
  },
  {
    label: "Touch Grass",
    value: {
      id: "grass",
      temp: "rgba(20, 37, 22, 1)",
      accent: "rgba(21, 159, 9, 1)",
    },
  },
  {
    label: "Lime Margarita",
    value: {
      id: "lime",
      temp: "rgba(27, 39, 21, 1)",
      accent: "rgba(87, 185, 1, 1)",
    },
  },
  {
    label: "Sage Wisdom",
    value: {
      id: "sage",
      temp: "rgba(60, 60, 17, 1)",
      accent: "rgba(134, 154, 86, 1)",
    },
  },
  {
    label: "Coffee with Cream",
    value: {
      id: "coffee",
      temp: "rgba(51, 36, 23, 1)",
      accent: "rgba(159, 133, 93, 1)",
    },
  },
  {
    label: "The Bronze Age",
    value: {
      id: "bronze",
      temp: "rgba(71, 61, 30, 1)",
      accent: "rgba(182, 152, 68, 1)",
    },
  },
  {
    label: "Tangerine Machine",
    value: {
      id: "tangerine",
      temp: "rgba(65, 50, 20, 1)",
      accent: "rgba(221, 134, 4, 1)",
    },
  },
  {
    label: "Citrus",
    value: {
      id: "orange",
      temp: "rgba(42, 28, 16, 1)",
      accent: "rgba(236, 104, 10, 1)",
    },
  },
  {
    label: "Cherry Cola",
    value: {
      id: "cherry",
      temp: "rgba(54, 19, 23, 1)",
      accent: "rgba(213, 20, 52, 1)",
    },
  },
  {
    label: "Bubblegum Pop",
    value: {
      id: "pink",
      temp: "rgba(51, 21, 44, 1)",
      accent: "rgba(223, 68, 143, 1)",
    },
  },
  {
    label: "Magenta is Not a Color",
    value: {
      id: "magenta",
      temp: "rgba(46, 20, 38, 1)",
      accent: "rgba(219, 26, 184, 1)",
    },
  },
  {
    label: "Lilac Attack",
    value: {
      id: "lilac",
      temp: "rgba(44, 24, 38, 1)",
      accent: "rgba(156, 103, 149, 1)",
    },
  },
  {
    label: "Professor Plum",
    value: {
      id: "plum",
      temp: "rgba(38, 22, 42, 1)",
      accent: "rgba(142, 49, 209, 1)",
    },
  },
  {
    label: "Violet Tendancies",
    value: {
      id: "violet",
      temp: "rgba(48, 26, 64, 1)",
      accent: "rgba(129, 32, 249, 1)",
    },
  },
  {
    label: "Indigo Montoya",
    value: {
      id: "indigo",
      temp: "rgba(37, 26, 64, 1)",
      accent: "rgba(80, 19, 223, 1)",
    },
  },
];

export const backgroundIntensities: Record<string, string> = {
  none: "0%",
  veryLight: "2%",
  light: "8%",
  mediumLight: "15%",
  medium: "25%",
  mediumStrong: "50%",
  strong: "75%",
  full: "100%",
};

export const inputSizes: Record<string, string> = {
  xs: "0.9rem",
  sm: "0.95rem",
  md: "1rem",
  lg: "1.1rem",
  xl: "1.2rem",
  "2xl": "1.3rem",
};

export const monoThemeIds = monoThemes.map((theme) => theme.value.id);
