import type { FormKitSchemaDefinition } from "@formkit/core";

export function trim(s: string, chars: RegExp): string {
  // Trim from the start
  let start = 0;
  while (start < s.length && chars.test(s[start])) {
    start++;
  }

  // Trim from the end
  let end = s.length - 1;
  while (end > start && chars.test(s[end])) {
    end--;
  }

  return s.slice(start, end + 1);
}

export function fileToString(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      // Ensure reader.result is a string before resolving the promise
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject(new Error("Could not read file as base64 string"));
      }
    };

    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file);
  });
}

export function firstName(name?: string | null): string {
  if (!name) return "friend";
  return name.split(" ")[0];
}

export async function makeHash(str: string) {
  const msgUint8 = new TextEncoder().encode(str);
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}

export function makePromptHuman(promptName: string): string {
  return promptName
    .replace(/^route\s*(?:to\s*)?/i, "") // Remove 'route' or 'route to' from start
    .replace(/([A-Z])/g, " $1") // Add space before capitals
    .replace(/([A-Z])([A-Z])(?=[a-z])/g, "$1 $2") // Split consecutive capitals
    .replace(/Prompt$/, "") // Remove trailing 'Prompt'
    .toLowerCase() // Convert to lowercase
    .trim(); // Remove extra spaces
}
