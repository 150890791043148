export default defineNuxtRouteMiddleware(async (to, from) => {
  const session = useUserSession();
  if (
    session &&
    session.user.value?.id &&
    !session.user.value.email &&
    to.path !== "/confirm-email" &&
    to.path !== "/verify-email" &&
    to.path !== "/logout"
  ) {
    return navigateTo("/confirm-email");
  }

  const privatePaths = ["/dashboard", "/editor/preview"];
  const isPrivate = privatePaths.some((path) => to.path.startsWith(path));

  if (isPrivate && !session?.user.value?.id) {
    return navigateTo("/login");
  }
});
