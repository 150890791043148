<script setup lang="ts">
import { onClickOutside, useDraggable } from "@vueuse/core";
import { gsap } from "gsap";

const system = useSystemStore();
const {
  commandPaletteOpen,
  commandPaletteCommands,
  commandPaletteMode,
  editorPreviewOpen,
  editorHistoryOpen,
  viewportWidth,
} = storeToRefs(system);

const form = useCurrentForm();
const theme = form.value?.theme;
const router = useRouter();
type Command = (typeof commandPaletteCommands.value)[number];

const commandPalette = ref<HTMLElement | null>(null);
const commandPaletteSearchInput = ref<HTMLInputElement | null>(null);
const commandPaletteSearch = ref("");
const filteredCommands = ref<Command[]>(commandPaletteCommands.value);
const initialMessage = ref<string | null>(null);

const activeCommandIndex = ref(0);
const interactionMethod = ref<"keyboard" | "mouse">("keyboard");

const commandActions = ref<Record<string, () => void>>({
  publish: () => {
    const publishButton = document.getElementById("publish-button");
    if (publishButton) {
      publishButton.click();
    }
  },
  preview: () => {
    editorPreviewOpen.value = true;
  },
  history: () => {
    editorHistoryOpen.value = true;
  },
  dashboard: () => {
    router.push("/dashboard/my-forms");
  },
  results: () => {
    const formId = form.value?.id;
    if (formId) {
      router.push(`/dashboard/${formId}/results`);
    }
  },
});

function executeCommand() {
  const command = filteredCommands.value[activeCommandIndex.value];
  if (command) {
    commandPaletteMode.value = command.id;
    // if the command has an action, execute it
    // and then close the command palette
    if (
      commandActions.value[command.id] &&
      typeof commandActions.value[command.id] === "function"
    ) {
      commandActions.value[command.id]();
      closeCommandPalette();
    }
  }
}

function filterCommands() {
  initialMessage.value = null;
  const route = useRoute();
  const searchTerm = commandPaletteSearch.value.toLowerCase();

  // Fuzzy search function
  function fuzzyMatch(pattern: string, str: string): boolean {
    let patternIdx = 0;
    let strIdx = 0;
    while (patternIdx < pattern.length && strIdx < str.length) {
      if (pattern[patternIdx] === str[strIdx]) {
        patternIdx++;
      }
      strIdx++;
    }
    return patternIdx === pattern.length;
  }

  const matchingCommands = commandPaletteCommands.value.filter((command) => {
    const name = command.name.toLowerCase();
    const aliases = command.aliases?.map((a) => a.toLowerCase()) || [];
    const matchesRoute =
      command.routes.includes("*") ||
      command.routes.some((r) => route.path.includes(r));

    return (
      matchesRoute &&
      (fuzzyMatch(searchTerm, name) ||
        aliases.some((alias) => fuzzyMatch(searchTerm, alias)))
    );
  });

  if (matchingCommands.length === 0) {
    // If no matches, show either edit or new form based on route
    if (route.path.includes("/editor")) {
      filteredCommands.value = [
        commandPaletteCommands.value.find((c) => c.id === "chat")!,
      ];
      initialMessage.value = searchTerm;
    } else {
      filteredCommands.value = [
        commandPaletteCommands.value.find((c) => c.id === "new")!,
      ];
      initialMessage.value = searchTerm;
    }
  } else {
    filteredCommands.value = matchingCommands;
    initialMessage.value = null;
  }
}

const inputPlaceholder = computed(() => {
  const route = useRoute();
  return route.path.includes("/editor")
    ? "type to filter commands or edit form..."
    : "type to filter commands or create a new form...";
});

const savedDragPosition = ref({ x: 0, y: 0 });
if (typeof window !== "undefined") {
  const savedPosition = localStorage.getItem("commandPalettePosition");
  if (savedPosition) {
    savedDragPosition.value = JSON.parse(savedPosition);
  } else {
    computeDefaultPosition();
  }
}
const { x, y, style } = useDraggable(commandPalette, {
  initialValue: savedDragPosition.value,
  preventDefault: true,
});

function computeDefaultPosition() {
  savedDragPosition.value = {
    x: "50%" as unknown as number, // percents work, but the type checker doesn't like it
    y: "50%" as unknown as number,
  };
}

watch([style, viewportWidth], setPosition);

function setPosition() {
  const viewport = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  // Get widget dimensions
  const widgetRect = commandPalette.value?.getBoundingClientRect();
  if (widgetRect) {
    // Snap to right edge if beyond viewport width
    if (x.value + widgetRect.width > viewport.width) {
      x.value = viewport.width - widgetRect.width;
    }
    // Snap to left edge if negative x position
    if (x.value < 0) {
      x.value = 0;
    }
    // Snap to bottom edge if beyond viewport height
    if (y.value + widgetRect.height > viewport.height) {
      y.value = viewport.height - widgetRect.height;
    }
    // Snap to top edge if negative y position
    if (y.value < 0) {
      y.value = 0;
    }
  }

  localStorage.setItem(
    "commandPalettePosition",
    JSON.stringify({ x: x.value, y: y.value })
  );
}

function setActiveCommandIndex(
  index: number,
  method: "keyboard" | "mouse" = "keyboard"
) {
  interactionMethod.value = method;
  activeCommandIndex.value =
    ((index % filteredCommands.value.length) + filteredCommands.value.length) %
    filteredCommands.value.length;

  // Only scroll into view if using keyboard navigation
  if (interactionMethod.value === "keyboard") {
    const commandElement = document.getElementById(
      `command-${filteredCommands.value[activeCommandIndex.value].id}`
    ) as HTMLElement;
    if (commandElement) {
      commandElement.scrollIntoView({ block: "nearest" });
    }
  }
}

function incrementActiveCommandIndex() {
  setActiveCommandIndex(activeCommandIndex.value + 1, "keyboard");
}

function decrementActiveCommandIndex() {
  setActiveCommandIndex(activeCommandIndex.value - 1, "keyboard");
}

function closeCommandPalette() {
  gsap.to(commandPalette.value, {
    opacity: 0,
    duration: 0.25,
    transform: "scale(0.8) translateY(10px)",
    ease: "power2.out",
    onComplete: () => {
      commandPaletteOpen.value = false;
      activeCommandIndex.value = 0;
      commandPaletteSearch.value = "";
      commandPaletteMode.value = null;
    },
  });
}

watch(commandPaletteSearch, () => {
  activeCommandIndex.value = 0;
});

onClickOutside(commandPalette, () => {
  closeCommandPalette();
});

onMounted(() => {
  filterCommands();
  setPosition();

  setTimeout(() => {
    commandPaletteSearchInput.value?.focus();
  }, 100);

  gsap.from(commandPalette.value, {
    opacity: 0.9,
    duration: 0.33,
    transform: "scale(0.8)",
    ease: "elastic.out(1.2, 0.75)",
  });
});
</script>

<template>
  <div
    ref="commandPalette"
    class="fixed flex flex-col bg-gradient-to-b from-white to-gray-50 z-20 rounded-lg ring-[2px] shadow-2xl shadow-gray-800/10 ring-slate-500/40 overflow-clip"
    :style="style"
    @keydown.down.prevent="incrementActiveCommandIndex"
    @keydown.up.prevent="decrementActiveCommandIndex"
    @keydown.enter.prevent.stop="executeCommand"
    @keydown.escape.prevent="closeCommandPalette"
  >
    <StyleVariableProvider :theme="theme" class="flex flex-col">
      <CommandPaletteChat
        v-if="commandPaletteMode === 'chat'"
        :initial-message="initialMessage"
      />

      <div v-if="commandPaletteMode === 'new'" class="p-2">
        <LazyNewFormInput
          :shadow="false"
          :initial-message="initialMessage ?? ''"
          :focus-on-mount="true"
          input-id="commandPaletteNewFormInput"
          @submit="closeCommandPalette"
        />
      </div>

      <CommandPaletteOpenForm v-if="commandPaletteMode === 'open'" />
      <CommandPaletteTeamPicker v-if="commandPaletteMode === 'teams'" />

      <template v-if="!commandPaletteMode">
        <div class="p-2">
          <input
            type="text"
            ref="commandPaletteSearchInput"
            v-model="commandPaletteSearch"
            :placeholder="inputPlaceholder"
            @input="filterCommands"
            class="w-full px-3 py-2 text-sm bg-slate-50 border border-slate-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-fk-accent focus:border-fk-accent"
          />
        </div>

        <div
          class="flex flex-col max-h-[min(50vh,300px)] overflow-y-auto py-px"
          @mousemove="interactionMethod = 'mouse'"
        >
          <div
            class="flex flex-col py-1.5 px-2 cursor-pointer"
            v-for="(command, index) in filteredCommands"
            :key="command.id"
            :id="`command-${command.id}`"
            @mousemove="setActiveCommandIndex(index, 'mouse')"
            @click="executeCommand"
            :class="{
              'bg-fk-accent-lightest ring-1 ring-inset ring-fk-accent-lighter':
                index === activeCommandIndex,
            }"
          >
            <div class="flex items-center w-full">
              <div class="items-center w-full">
                <span class="block text-sm mr-1">{{ command.name }}</span>
                <span class="block -mt-px text-xs text-slate-500">
                  {{ command.description }}
                </span>
              </div>
              <Icon
                :name="command.icon"
                class="h-5 w-5 text-slate-500 ml-auto"
              />
            </div>
          </div>
        </div>
      </template>

      <div
        class="flex overflow-clip items-center p-2 bg-gray-50 cursor-grab shrink-0"
      >
        <div class="flex items-center mr-2 h-6 w-full">
          <div class="mr-2 flex items-center">
            <Icon
              class="h-5 w-5 text-slate-500 group-hover:text-pink-500 mr-2"
              name="ri:chat-smile-ai-line"
            />
            <span class="text-xs text-slate-500">Command Palette</span>
          </div>
          <span class="ml-auto text-sm text-slate-600 flex items-center"
            ><kbd class="mr-0.5 mt-[2px] text-lg">⌘</kbd><kbd>K</kbd></span
          >
        </div>
      </div>
    </StyleVariableProvider>
  </div>
</template>
