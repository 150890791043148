import revive_payload_client_I1eH5EryGe from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_drizzle-orm@0.33.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_1IZvMSuKYW from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_drizzle-orm@0.33.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_WhcZD1lok3 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_drizzle-orm@0.33.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_hQTbUNFQfp from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_drizzle-orm@0.33.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_f42lWfeYE6 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_drizzle-orm@0.33.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_D37hiEyIW1 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_drizzle-orm@0.33.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_aB7ziit6m7 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_drizzle-orm@0.33.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_pUyy70BkTa from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.28.1_typescript@5.7.2_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_908EZEAw6g from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_drizzle-orm@0.33.0_rollup@4.28.1_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_F50ndpkkJU from "/opt/buildhome/repo/node_modules/.pnpm/@sentry+nuxt@8.45.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.29.0_@opentelemetry+instru_lwzvx35dscmvu5e4a3ua4l6ug4/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/buildhome/repo/.nuxt/sentry-client-config.mjs";
import plugin_client_nQuLfRbZPv from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-fathom@0.0.2_rollup@4.28.1/node_modules/nuxt-fathom/dist/runtime/plugin.client.mjs";
import session_client_ARMamQm5KR from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-auth-utils@0.3.9_rollup@4.28.1/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import plugin_dquQJXCpCL from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.10.1_rollup@4.28.1_vite@6.0.3_vue@3.5.13/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_6Ph1iQzNHk from "/opt/buildhome/repo/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/opt/buildhome/repo/.nuxt/formkitPlugin.mjs";
import floatingVue_JzRtS4Vfwe from "/opt/buildhome/repo/plugins/floatingVue.ts";
import formkit_DahpcjAQo5 from "/opt/buildhome/repo/plugins/formkit.ts";
export default [
  revive_payload_client_I1eH5EryGe,
  unhead_1IZvMSuKYW,
  router_WhcZD1lok3,
  payload_client_hQTbUNFQfp,
  navigation_repaint_client_f42lWfeYE6,
  check_outdated_build_client_D37hiEyIW1,
  chunk_reload_client_aB7ziit6m7,
  plugin_vue3_pUyy70BkTa,
  components_plugin_KR1HBZs4kY,
  prefetch_client_908EZEAw6g,
  sentry_client_F50ndpkkJU,
  sentry_client_config_o34nk2sJbg,
  plugin_client_nQuLfRbZPv,
  session_client_ARMamQm5KR,
  plugin_dquQJXCpCL,
  plugin_6Ph1iQzNHk,
  formkitPlugin_pZqjah0RUG,
  floatingVue_JzRtS4Vfwe,
  formkit_DahpcjAQo5
]