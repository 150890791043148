import { empty } from "@formkit/utils";
import { autocomplete } from "./autocomplete";
import { checkboxmultiple } from "./checkboxmultiple";
import { checkboxsingle } from "./checkboxsingle";
import { colorpicker } from "./colorpicker";
import { currency } from "./currency";
import { datepicker } from "./datepicker";
import { dropdown } from "./dropdown";
import { file } from "./file";
import { mask } from "./mask";
import { number } from "./number";
import { radio } from "./radio";
import { rating } from "./rating";
import { repeater } from "./repeater";
import { slider } from "./slider";
import { submit } from "./submit";
import { taglist } from "./taglist";
import { tel } from "./tel";
import { text } from "./text";
import { textarea } from "./textarea";
import { toggle } from "./toggle";

export {
  autocomplete,
  checkboxmultiple,
  checkboxsingle,
  colorpicker,
  currency,
  datepicker,
  dropdown,
  file,
  // group,
  mask,
  number,
  radio,
  rating,
  repeater,
  slider,
  submit,
  taglist,
  tel,
  text,
  textarea,
  toggle,
};

/**
 * Don’t use this on the front end:
 */
export const inputPrompts = {
  autocomplete,
  checkboxmultiple,
  checkboxsingle,
  colorpicker,
  currency,
  datepicker,
  dropdown,
  file,
  mask,
  number,
  radio,
  rating,
  repeater,
  slider,
  submit,
  taglist,
  tel,
  text,
  textarea,
  toggle,
};

export const inputsRequiringDetails: Array<ItemType> = [
  "autocomplete",
  "checkboxmultiple",
  "colorpicker",
  "currency",
  "datepicker",
  "dropdown",
  "file",
  "mask",
  "number",
  "radio",
  "rating",
  "repeater",
  "slider",
  "taglist",
];

export const components = [
  "page_break",
  "heading",
  "paragraph",
  "alert",
  "image",
  "video",
] as const;

export const llmComponents = ["page_break", "heading", "paragraph"] as const;
export const userOnlyComponents: Array<(typeof components)[number]> = [
  "image",
  "video",
  "alert",
];

export type LLMComponents = (typeof llmComponents)[number];

export type ItemType =
  | "autocomplete"
  | "checkboxmultiple"
  | "checkboxsingle"
  | "colorpicker"
  | "currency"
  | "datepicker"
  | "dropdown"
  | "file"
  | "mask"
  | "number"
  | "radio"
  | "rating"
  | "repeater"
  | "slider"
  | "submit"
  | "taglist"
  | "tel"
  | "text"
  | "textarea"
  | "page_break"
  | "heading"
  | "paragraph"
  | "toggle";

export const inputTypes = [
  "autocomplete",
  "checkboxmultiple",
  "checkboxsingle",
  "colorpicker",
  "currency",
  "datepicker",
  "dropdown",
  "file",
  "mask",
  "number",
  "radio",
  "rating",
  "repeater",
  "slider",
  "submit",
  "taglist",
  "tel",
  "text",
  "textarea",
  "toggle",
];
