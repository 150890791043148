<script setup lang="ts">
import type { FormTheme } from "@/types";
import {
  getThemeById,
  monoThemes,
  backgroundIntensities,
  inputSizes,
} from "~/assets/colorThemes";

const props = defineProps<{
  baseSpacing?: string;
  fontSize?: string;
  theme?: FormTheme;
  setPageBg?: boolean;
}>();
const route = useRoute();
const isScreenshotRoute = computed(() => {
  return route.path.includes("/screenshots");
});

const variableProvider = ref<HTMLDivElement | null>(null);
const bgSet = ref(false);

const activeTheme = computed(() => {
  return getThemeById(props.theme?.colorThemeId || "pink", monoThemes);
});
const activeBackgroundIntensity = computed(() => {
  return (
    backgroundIntensities &&
    backgroundIntensities[props.theme?.bgColorIntensity || 0]
  );
});
const activeInputSize = computed(() => {
  return (
    props.fontSize ??
    ((inputSizes && inputSizes[props.theme?.formInputSize || "1em"]) || "1em")
  );
});
const inputSpacing = computed(() => {
  return `${props.theme?.formInputSpacing}em` || "1.2em";
});
const baseSpacing = computed(() => {
  return (
    props.baseSpacing ??
    (`${Math.max(
      0.5,
      Math.min((props.theme?.formInputSpacing || 1.2) * 0.4, 0.6)
    )}em` ||
      "1.2em")
  );
});

const currentFontFamily = ref(props.theme?.formFontFamily || "");

watch(
  () => props.theme?.formFontFamily,
  (newFontFamily) => {
    if (newFontFamily) {
      currentFontFamily.value = newFontFamily;
    }
  },
  { immediate: true }
);

useHead(() => {
  const fontDisplay = isScreenshotRoute.value ? "block" : "swap";
  return {
    link: [
      {
        rel: "preconnect",
        href: "https://fonts.googleapis.com",
      },
      {
        rel: "preconnect",
        href: "https://fonts.gstatic.com",
        crossorigin: "",
      },
      {
        rel: "preload",
        as: "style",
        href: `https://fonts.googleapis.com/css?family=${encodeURIComponent(
          currentFontFamily.value
        )}:regular,bold,italic&display=${fontDisplay}`,
      },
      {
        rel: "stylesheet",
        href: `https://fonts.googleapis.com/css?family=${encodeURIComponent(
          currentFontFamily.value
        )}:regular,bold,italic&display=${fontDisplay}`,
        media: "print",
        onload: "this.media='all'",
      },
    ],
  };
});

onMounted(() => {
  const computedStyles = window.getComputedStyle(variableProvider.value!);
  const accentColor = computedStyles.getPropertyValue("--fk-color-accent-base");
  const accentColorLighter = computedStyles.getPropertyValue(
    "--fk-color-accent-lighter"
  );
  const pageBgColor = computedStyles.getPropertyValue(
    "--fk-color-temp-page-bg"
  );

  if (props.setPageBg) {
    document.body.style.backgroundColor = pageBgColor;
    bgSet.value = true;
  }

  const loadingIndicator = document.querySelector("body");
  if (loadingIndicator) {
    (loadingIndicator as HTMLElement).style.setProperty(
      "--fk-color-accent-base",
      accentColor
    );
    (loadingIndicator as HTMLElement).style.setProperty(
      "--fk-color-accent-lighter",
      accentColorLighter
    );
  }
});
</script>

<template>
  <div
    ref="variableProvider"
    :class="`
      __variable-provider
      group/container
      flex
      flex-1
      w-full

      ${
        setPageBg
          ? 'bg-fk-temp-page-bg [&_[data-layout].outer]:!bg-transparent'
          : ''
      }
      ${bgSet ? '!bg-transparent' : ''}
    `"
    :data-input-style="theme ? theme.formInputStyle : 'rounded'"
  >
    <slot />
  </div>
</template>

<style scoped>
.__variable-provider *:not(.formkit-overlay, .formkit-input)::-moz-selection {
  @apply text-white bg-fk-accent;
}
.__variable-provider *:not(.formkit-overlay, .formkit-input)::selection {
  @apply text-white bg-fk-accent;
}

.__variable-provider {
  --fk-color-temp-base: v-bind("activeTheme?.temp");
  --fk-color-accent-base: v-bind("activeTheme?.accent");
  --fk-color-temp-page-bg-intensity: v-bind("activeBackgroundIntensity");
  --fk-font-size: v-bind("activeInputSize");
  --fk-input-gap: v-bind("inputSpacing");
  --fk-spacing-base: v-bind("baseSpacing");
  --fk-font-family: v-bind("props.theme?.formFontFamily || 'inherit'");
  --fk-font-size-boost: v-bind(
    "props.theme?.formFontSizeBoost + 'em' || '0em'"
  );

  --fk-font-size-base: calc(var(--fk-font-size) + var(--fk-font-size-boost));
  --fk-radius-base: 0.3rem;
  --fk-radius-sm: max(0.1rem, min(calc(var(--fk-radius-base) * 0.75), 0.5rem));
  --fk-radius-lg: max(0.3rem, min(calc(var(--fk-radius-base) * 1.25), 1rem));
  --fk-radius-xl: max(0.5rem, min(calc(var(--fk-radius-base) * 1.5), 1.5rem));

  --fk-spacing-sm: calc(var(--fk-spacing-base) * 0.875);
  --fk-spacing-xs: calc(var(--fk-spacing-base) * 0.5);
  --fk-spacing-tiny: calc(var(--fk-spacing-base) * 0.1);
  --fk-spacing-md: calc(var(--fk-spacing-base) * 1.25);
  --fk-spacing-lg: calc(var(--fk-spacing-base) * 1.5);
  --fk-spacing-xl: calc(var(--fk-spacing-base) * 2);
  --fk-spacing-2xl: calc(var(--fk-spacing-base) * 2.25);
  --fk-spacing-3xl: calc(var(--fk-spacing-base) * 3.25);
  --fk-spacing-4xl: calc(var(--fk-spacing-base) * 4.5);

  --fk-font-size-large: calc((var(--fk-font-size-base) * 1.125));
  --fk-font-size-larger: calc((var(--fk-font-size-base) * 1.25));
  --fk-font-size-small: calc(
    min(60px, max(12px, calc(var(--fk-font-size-base) * 0.9)))
  );
  --fk-font-size-smaller: calc(
    min(50px, max(11px, calc(var(--fk-font-size-base) * 0.8)))
  );
  --fk-font-size-smallest: calc(
    min(48px, max(11px, calc(var(--fk-font-size-base) * 0.7)))
  );

  --fk-line-height-base: 1.5;
  --fk-line-height-small: 1.4;
  --fk-line-height-smaller: 1.3;
  --fk-line-height-large: 1.6;
  --fk-line-height-larger: 1.8;

  /* by default all variations reference the base variable, if the browser supports color-mix we'll change them */
  --fk-color-temp-light: var(--fk-color-temp-base);
  --fk-color-temp-lighter: var(--fk-color-temp-base);
  --fk-color-temp-lightest: var(--fk-color-temp-base);
  --fk-color-temp-dark: var(--fk-color-temp-base);
  --fk-color-temp-darker: var(--fk-color-temp-base);
  --fk-color-temp-page-bg: rgb(216, 216, 216);
  --fk-color-temp-form-bg: rgb(255, 255, 255);
  --fk-color-temp-form-bg-dark: rgb(225, 225, 225);

  /* by default all variations reference the base variable, if the browser supports color-mix we'll change them */
  --fk-color-accent-light: var(--fk-color-accent-base);
  --fk-color-accent-lighter: var(--fk-color-accent-base);
  --fk-color-accent-lightest: var(--fk-color-accent-base);
  --fk-color-accent-dark: var(--fk-color-accent-base);
  --fk-color-accent-darker: var(--fk-color-accent-base);
}

@supports (color: color-mix(in srgb, #000, #fff)) {
  .__variable-provider {
    --fk-color-temp-light: color-mix(
      in srgb,
      var(--fk-color-temp-base) 70%,
      white
    );
    --fk-color-temp-lighter: color-mix(
      in srgb,
      var(--fk-color-temp-base) 40%,
      white
    );
    --fk-color-temp-lightest: color-mix(
      in srgb,
      var(--fk-color-temp-base) 20%,
      white
    );
    --fk-color-temp-dark: color-mix(
      in srgb,
      var(--fk-color-temp-base) 70%,
      black
    );
    --fk-color-temp-darker: color-mix(
      in srgb,
      var(--fk-color-temp-base) 40%,
      black
    );
    --fk-color-temp-page-bg: color-mix(
      in srgb,
      var(--fk-color-accent-base) var(--fk-color-temp-page-bg-intensity),
      var(--fk-color-temp-form-bg)
    );
    --fk-color-temp-form-bg: color-mix(
      in srgb,
      color-mix(in srgb, var(--fk-color-temp-base) 12%, transparent),
      rgba(255, 255, 255, 0.1)
    );
    --fk-color-temp-form-bg-dark: color-mix(
      in srgb,
      color-mix(in srgb, var(--fk-color-temp-base) 33%, transparent),
      rgba(255, 255, 255, 0.1)
    );

    --fk-color-accent-light: color-mix(
      in srgb,
      var(--fk-color-accent-base) 70%,
      white
    );
    --fk-color-accent-lighter: color-mix(
      in srgb,
      var(--fk-color-accent-base) 40%,
      white
    );
    --fk-color-accent-lightest: color-mix(
      in srgb,
      var(--fk-color-accent-base) 10%,
      white
    );
    --fk-color-accent-dark: color-mix(
      in srgb,
      var(--fk-color-accent-base) 70%,
      black
    );
    --fk-color-accent-darker: color-mix(
      in srgb,
      var(--fk-color-accent-base) 40%,
      black
    );
  }
}

:global(.fk-text-base) {
  font-size: var(--fk-font-size-base);
  line-height: var(--fk-line-height-base);
}

/* Large Text Size */
:global(.fk-text-large) {
  font-size: var(--fk-font-size-large);
  line-height: var(--fk-line-height-large);
}

/* Larger Text Size */
:global(.fk-text-larger) {
  font-size: var(--fk-font-size-larger);
  line-height: var(--fk-line-height-larger);
}

/* Small Text Size */
:global(.fk-text-small) {
  font-size: var(--fk-font-size-small);
  line-height: var(--fk-line-height-small);
}

/* Smaller Text Size */
:global(.fk-text-smaller) {
  font-size: var(--fk-font-size-smaller);
  line-height: var(--fk-line-height-smaller);
}

/* Smallest Text Size */
:global(.fk-text-smallest) {
  font-size: var(--fk-font-size-smallest);
  line-height: var(--fk-line-height-smaller);
}
</style>
