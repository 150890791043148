import "floating-vue/dist/style.css";
import FloatingVue from "floating-vue";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(FloatingVue, {
    themes: {
      editor: {
        $resetCSS: true,
        autoHide: true,
        distance: -25,
        handleResize: true,
        instantMove: true,
        placement: "left-start",
        skidding: -1,
        strategy: "fixed",
        triggers: ["click"],
      },
    },
  });
});
