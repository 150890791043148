export async function log(content: string) {
  try {
    await $fetch(
      "https://discord.com/api/webhooks/1280868509701902398/98Mh4VDsXSA0bApZ29VgoW6YKi0MRyGHWwBtQGnJzck-1YqMx2V5TstmUumwI2xL7QdQ",
      {
        method: "POST",
        body: {
          content,
        },
      }
    );
  } catch (e) {
    console.error(e);
  }
}

export async function logPayment(content: string) {
  await $fetch(
    "https://discord.com/api/webhooks/1280868509701902398/98Mh4VDsXSA0bApZ29VgoW6YKi0MRyGHWwBtQGnJzck-1YqMx2V5TstmUumwI2xL7QdQ",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        content,
      },
    }
  );
}
