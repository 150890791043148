import type {
  FormStore,
  SubmitData,
  LayoutOptions,
  History,
  FormNode,
} from "~/types";
import { streamId, streamMetaData } from "~/utils/stream";
import { createStore, saveHistory } from "~/utils/form";
import { postProcessForm } from "~/utils/schema";

export const useFormStore = defineStore("formStore", () => {
  const forms = ref<Record<string, FormStore>>({});
  const generatingForm = ref<boolean | null>(false);
  const blurHashes = ref<Record<string, Uint8ClampedArray>>({});
  async function loadForm(id: string) {
    const store = createStore(id);
    forms.value[id] = store;
    try {
      store.loading = true;
      const form = await $fetch<FormStore>(`/api/forms/${id}`);
      store.schema = form.schema;
      store.history = form.history;
      store.title = form.title;
      store.theme = form.theme;
    } catch (err: unknown) {
      console.error(err);
      useSystemStore().displayError("Sorry, we could not find that form.");
      return navigateTo("/dashboard");
    } finally {
      await nextTick();
      store.loading = false;
    }
  }

  async function createForm(data: SubmitData, onId?: (id: string) => void) {
    const history: History = { user_prompt: data.prompt };
    let result: ReadableStream<Uint8Array>;
    generatingForm.value = true;

    if (Array.isArray(data.file) && data.file.length && data.file[0].file) {
      const file = data.file[0].file;
      history.file_name = data.file[0].name;

      if (file && file.type === "application/pdf" && import.meta.client) {
        const pdfData = new Uint8Array(await file.arrayBuffer());
        const { renderPdfToImages } = await import("~/utils/pdfToImage.client");
        const images = await renderPdfToImages(pdfData);
        data.fileData = images;
      } else if (file) {
        data.fileData = await fileToString(file);
      }

      delete data.file;
    }
    try {
      result = await $fetch<ReadableStream>("/api/forms", {
        method: "post",
        body: data,
        responseType: "stream",
      });
    } catch (err: unknown) {
      generatingForm.value = false;
      return handleBackendError(err);
    }
    const store = createStore("");
    store.loading = true;
    await readToolStream(result, {
      onId: (id: string) => {
        store.id = id;
        forms.value[id] = store;
        if (onId) onId(id);
      },
      store,
    });
    postProcessForm(store);
    await saveHistory(store, history);
    store.loading = false;
    generatingForm.value = false;
  }
  /**
   * Generate a new form!
   * @param data - The data to submit
   */
  // async function createForm(data: SubmitData, onId?: (id: string) => void) {
  //   const history: History = { user_prompt: data.prompt };
  //   generatingForm.value = true;

  //   if (Array.isArray(data.file) && data.file.length && data.file[0].file) {
  //     const file = data.file[0].file;
  //     history.file_name = data.file[0].name;

  //     if (file && file.type === "application/pdf" && import.meta.client) {
  //       const pdfData = new Uint8Array(await file.arrayBuffer());
  //       const { renderPdfToImages } = await import("~/utils/pdfToImage.client");
  //       const images = await renderPdfToImages(pdfData);
  //       data.fileData = images;
  //     } else if (file) {
  //       data.fileData = await fileToString(file);
  //     }

  //     delete data.file;
  //   }

  //   let result: ReadableStream<Uint8Array>;
  //   try {
  //     result = await $fetch<ReadableStream>("/api/forms", {
  //       method: "post",
  //       body: data,
  //       responseType: "stream",
  //     });
  //   } catch (err: unknown) {
  //     generatingForm.value = false;
  //     return handleBackendError(err);
  //   }
  //   const store = createStore("");
  //   store.loading = true;
  //   const save = saveHistory(store, history, true);

  //   // set the chosen layout
  //   if (data.layout) {
  //     store.theme.layout = data.layout as LayoutOptions;
  //   }

  //   watchEffect(() => {
  //     if (store.theme.bgImageSearchQueryDefault) {
  //       initializeUnsplash(store);
  //     }
  //   });

  //   await readMultiplexedStream(result.getReader(), (channel, stream) => {
  //     if (channel === "id") {
  //       streamId(stream).then((id) => {
  //         if (!id) return;
  //         store.id = id;
  //         forms.value[id] = store;
  //         if (onId) onId(id);
  //       });
  //     } else if (channel === "outline") {
  //       const [metaStream, outlineStream] = stream.tee();
  //       streamOutline(store, outlineStream);
  //       streamMetaData(store, metaStream);
  //     } else if (channel.startsWith("{")) {
  //       // We expect the channel data here to include the idx and cols.
  //       const { idx, type } = JSON.parse(channel);
  //       const baseInput: Record<string, string> = removeEmptyValues({
  //         idx,
  //         type,
  //       });
  //       if (type === "repeater") {
  //         baseInput.outerClass = "@container/repeater";
  //         baseInput.contentClass =
  //           "w-full @md/repeater:grid @md/repeater:grid-cols-2 gap-4";
  //       }
  //       if (type !== "submit") baseInput.label = "Loading...";
  //       streamItem(store, stream, baseInput);
  //     } else {
  //       // Some unused mux channel here
  //     }
  //   });
  //   postProcessForm(store);
  //   store.loading = false;
  //   await save({ id: store.id });
  //   generatingForm.value = false;
  // }

  return {
    forms,
    blurHashes,
    generatingForm,
    loadForm,
    createForm,
  };
});

async function initializeUnsplash(store: FormStore) {
  // get a random Unsplash image
  let imageResult;
  try {
    imageResult = await $fetch(`/unsplash/random`, {
      method: "get",
      responseType: "json",
      query: {
        query: store.theme.bgImageSearchQueryDefault,
      },
    });

    const firstResult = (imageResult.body && imageResult.body[0]) || "";
    if (
      imageResult.body &&
      typeof firstResult !== "string" &&
      firstResult.urls
    ) {
      const rawUrl = firstResult.urls.full;
      store.theme.bgImageUrl = `${rawUrl}&w=1600&h=1600&&fm=webp`;
      store.theme.bgImageThumbUrl = `${rawUrl}&w=200&h=200&&fm=webp`;
      store.theme.bgImageBlurHash = firstResult.blur_hash || "";
    }
  } catch (err: unknown) {
    handleBackendError(err);
  }
}
