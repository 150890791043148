import { cloneAny } from "@formkit/utils";
import type { FormNode, Page } from "../types";

export function asciiOutline(pages: Page[], prefix = "", depth = 0): string {
  let str = !prefix ? `form` : "";
  pages.forEach((page, pageIndex) => {
    const pageTitle = `Page ${pageIndex + 1}: ${page.title}`;
    const isLastPage = pageIndex === pages.length - 1;
    str +=
      "\n" +
      prefix +
      (isLastPage ? "└─" : "├─") +
      (page.schema.length ? "┬ " : "─ ") +
      pageTitle;
    if (page.schema.length) {
      str += traverseSchemaNodes(
        page.schema,
        prefix + (isLastPage ? "   " : "│  "),
        depth + 1
      );
    }
  });
  return str;
}

function traverseSchemaNodes(
  nodes: FormNode[],
  prefix: string,
  depth: number
): string {
  let str = "";
  nodes.forEach((element, index) => {
    let text: string;
    if (
      "$cmp" in element &&
      element.$cmp === "Placeholder" &&
      element.props &&
      typeof element.props === "object" &&
      "inputType" in element.props
    ) {
      text = `**GENERATE A ${element.props.inputType} INPUT IN THIS LOCATION**`;
    } else {
      text = `${element.label ?? element.content} (type: ${
        element.$formkit || element.type
      }, name: ${element.name}, idx: ${element.idx})`;
    }
    const isLast = index === nodes.length - 1;
    str +=
      "\n" +
      prefix +
      (isLast ? "└─" : "├─") +
      (element.children && element.children.length ? "┬ " : "─ ") +
      text;
    if (element.children && element.children.length) {
      str += traverseSchemaNodes(
        element.children,
        prefix + (isLast ? "   " : "│  "),
        depth + 1
      );
    }
  });
  return str;
}
