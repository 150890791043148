<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";

interface TypingEffectProps {
  words: string[];
  typingDelay?: number;
  deleteDelay?: number;
  pauseDelay?: number;
  blinkDelay?: number;
  once?: boolean;
  cursor?: boolean;
}

const props = withDefaults(defineProps<TypingEffectProps>(), {
  typingDelay: 50,
  deleteDelay: 20,
  pauseDelay: 2400,
  blinkDelay: 400,
  once: false,
  cursor: true,
});

const randomizedWords = props.words.sort(() => Math.random() - 0.5);

const currentIndex = ref(0);
const currentWord = ref("");
const isTyping = ref(false);
const blink = ref(false);
let intervalId: ReturnType<typeof setInterval> | null = null;
let blinkIntervalId: ReturnType<typeof setInterval> | null = null;

const startTyping = () => {
  isTyping.value = true;
  typeWord();
};

const stopTyping = () => {
  isTyping.value = false;
  if (intervalId) {
    clearInterval(intervalId);
    intervalId = null;
  }
  stopBlinking();
};

const typeWord = () => {
  const word = randomizedWords[currentIndex.value];
  let index = 0;

  intervalId = setInterval(() => {
    if (index < word.length) {
      currentWord.value += word[index];
      index++;
    } else {
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      }
      startBlinking();
      if (props.once && currentIndex.value === props.words.length - 1) {
        stopTyping();
        return;
      }
      setTimeout(() => {
        deleteWord();
      }, props.pauseDelay);
    }
  }, props.typingDelay);
};

const deleteWord = () => {
  stopBlinking();
  intervalId = setInterval(() => {
    if (currentWord.value.length > 0) {
      currentWord.value = currentWord.value.slice(0, -1);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      }
      currentIndex.value = (currentIndex.value + 1) % props.words.length;
      startTyping();
    }
  }, props.deleteDelay);
};

const startBlinking = () => {
  blink.value = true;
  blinkIntervalId = setInterval(() => {
    blink.value = !blink.value;
  }, props.blinkDelay);
};

const stopBlinking = () => {
  blink.value = false;
  if (blinkIntervalId) {
    clearInterval(blinkIntervalId);
    blinkIntervalId = null;
  }
};

onMounted(() => {
  startTyping();
});

onUnmounted(() => {
  stopTyping();
});
</script>

<template>
  <span class="inline-block whitespace-nowrap">
    {{ currentWord
    }}<span
      v-if="props.cursor"
      :class="`${
        blink ? 'opacity-70' : 'opacity-100'
      } transition-opacity ease-in-out duration-100`"
      >_</span
    ></span
  >
</template>
