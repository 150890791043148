<script setup lang="ts">
import type { ChatMessage } from "~/types";
import { token } from "@formkit/utils";
import type { FormKitNode } from "@formkit/core";

const props = defineProps<{
  initialMessage: string | null;
}>();

const system = useSystemStore();
const { editorCanvasOpen } = storeToRefs(system);
const form = useCurrentForm();
const history = ref<ChatMessage[]>([
  {
    role: "assistant",
    content: "Hey! How can I help you edit this form?",
    id: token(),
  },
]);
const historyWithoutSuggestions = computed(() =>
  history.value
    .filter((m) => m.role !== "suggestions")
    .map(({ role, content, id }) => ({ role, content, id }))
);

const chatHistory = ref<HTMLElement | null>(null);

const chatInputNode = ref<FormKitNode | null>(null);
function setChatInputNode(node: FormKitNode) {
  chatInputNode.value = node;
}

async function sendPrompt(data: { prompt?: string }, node: FormKitNode) {
  if (!data.prompt?.trim()) {
    return;
  }
  history.value.push({
    role: "user",
    id: token(),
    content: data.prompt ?? "",
  });
  node.reset();
  const res = await $fetch<ReadableStream<Uint8Array>>(
    `/api/forms/${form.value?.id}/chat`,
    {
      method: "POST",
      body: {
        ...data,
        form: form.value?.schema,
        history: historyWithoutSuggestions.value,
      },
      responseType: "stream",
    }
  );
  await readToolStream(res, {
    history,
  });
  focusChatInput();
}

function focusChatInput() {
  setTimeout(() => {
    const chatInput = document.getElementById("chat-input");
    if (chatInput) {
      chatInput.focus();
    }
  }, 100);
}

onMounted(() => {
  focusChatInput();

  editorCanvasOpen.value = false;
  nextTick(() => {
    editorCanvasOpen.value = true;
  });

  if (props.initialMessage && chatInputNode.value) {
    setTimeout(() => {
      sendPrompt({ prompt: props.initialMessage }, chatInputNode.value);
    }, 100);
  }
});
</script>

<template>
  <div
    ref="chatHistory"
    :class="`
        flex
        flex-col
        bottom-full
        w-full

        flex-1
        z-20
        h-[150px]
        transition-all
        backdrop-blur-[2px]
        overflow-auto
      `"
  >
    <span class="flex flex-col mt-auto p-2 gap-5" v-auto-animate>
      <template v-for="(message, index) in history" :key="history.id">
        <div
          v-if="message.role === 'assistant'"
          :class="`
            w-full min-h-[2.75em] min-w-[3em] p-2 rounded-lg border border-slate-200 text-sm max-w-[66.666%] mr-auto shadow-sm
            ${'error' in message ? 'bg-yellow-50' : ''}
          `"
        >
          <p>
            <TextRotator
              :typing-delay="10"
              :cursor="false"
              :once="true"
              v-if="index === 0"
              :words="[message.content]"
            />
            <span v-else>{{ message.content }}</span>
          </p>
        </div>
        <div
          v-else
          class="bg-fk-accent-lightest ring-1 ring-fk-accent-lighter p-2 rounded-lg text-sm max-w-[90%] ml-auto shadow-sm text-fk-accent-darker"
        >
          <p>{{ message.content }}</p>
        </div>
      </template>
    </span>
  </div>

  <div id="chat-section" class="flex flex-col">
    <FormKit
      type="form"
      @node="setChatInputNode"
      :actions="false"
      @submit="sendPrompt"
      #default="{ node, state: { loading } }"
    >
      <div class="flex items-center justify-between shrink-0 p-2">
        <FormKit
          id="chat-input"
          type="textarea"
          name="prompt"
          auto-height
          placeholder="What would you like to change about this form?"
          :outer-class="`
            !mb-0
            !max-w-none
            rounded-lg
            border
            border-gray-300
            !max-h-[20vh]
            overflow-auto
          `"
          :inner-class="`
            !bg-transparent
            !mb-0
            !border-none
            !rounded-none
          `"
          :input-class="`
            !h-[4.5em]
            overflow-clip
            w-full
            flex-[1_0_50%]
            resize-none
            !bg-slate-50
            !text-slate-800
            pr-2
            text-base
            !leading-tight
            shadow-none
            [scroll-padding-block:0.75rem]
            placeholder:text-slate-500/90
            disabled:bg-transparent
            disabled:opacity-80
            [&amp;_textarea]:px-0
          `"
          @keydown.enter.prevent="node.submit()"
        >
          <template #suffix>
            <button
              class="sticky bottom-2 text-white bg-slate-700 flex items-center mt-auto w-7 h-7 p-2 mr-2 mb-1.5 rounded-full"
              type="submit"
            >
              <FormKitIcon
                v-if="!loading"
                icon="arrowUp"
                class="w-full block [&>svg]:block [&>svg]:w-full"
              />
              <Loader v-else />
            </button>
          </template>
        </FormKit>
      </div>
    </FormKit>
  </div>
</template>
